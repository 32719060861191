import { gql } from "@apollo/client";
import { FlatfileRecord } from "@flatfile/hooks";
import { Button } from "@material-ui/core";
import { useTrack } from "src/analytics";
import {
  AssembleButton,
  AssembleButtonProps,
} from "src/components/AssembleButton/AssembleButton";
import { AssembleFlatfileButton } from "src/components/AssembleFlatfileButton";
import {
  CurrencyField,
  EmployeeIdentifierFields,
  VariableCompIdentifierFields,
} from "src/models/csv/Fields";
import { getEmployeeIdentifierValidators } from "src/models/csv/Fields/EmployeeIdentifierFields";
import { getVariableCompIdentifierValidators } from "src/models/csv/Fields/VariableCompIdentifierFields";
import { noop } from "src/test-helpers";
import { flatFileDataToValues, toFFRecordLike } from "src/utils";
import {
  CompUnit,
  CurrencyCode,
  VariableCompImportButton_employee as Employee,
  ImportVariableCompInput,
  VariableCompType,
} from "../../../__generated__/graphql";
import { matchEmployee, validateDate } from "../../../models/csv/EmployeeCSV";
import { useImportVariableComp } from "../../../mutations/CashCompensation";
import { useCurrencies } from "../../CurrenciesContext";

type Props = {
  employees: Employee[];
  onCancel?: () => unknown;
  onUpload?: () => unknown;
  buttonOverrideProps?: AssembleButtonProps;
};

export function VariableCompImportButton({
  employees,
  onCancel,
  onUpload = noop,
  buttonOverrideProps,
}: Props): JSX.Element {
  const validateRecord = recordValidator(employees);
  const { currencies } = useCurrencies();
  const upload = useImportVariableComp();
  const { trackEvent } = useTrack();

  return (
    <AssembleFlatfileButton
      settings={{
        name: "Add your Variable Compensation Data",
        sheets: [
          {
            name: "Add your Variable Compensation Data",
            slug: "variable_comp",
            fields: [
              ...EmployeeIdentifierFields({ allowDuplicates: true }),
              ...VariableCompIdentifierFields,
              ...CurrencyField(currencies),
            ],
          },
        ],
      }}
      validators={[
        ...getEmployeeIdentifierValidators(),
        ...getVariableCompIdentifierValidators(),
      ]}
      onRecordChange={validateRecord}
      onData={async ({ sheet }) => {
        if (sheet == null) {
          throw new Error("Sheet Not Found");
        }
        const data = await sheet.validData();
        await upload(parseData(flatFileDataToValues(data), employees));

        trackEvent({
          object: "Employee Target Variable Comp",
          action: "Imported",
        });

        onUpload();
      }}
      onCancel={onCancel}
      render={(launch) => {
        const onClickWrapper = () => {
          trackEvent({
            object: "Import Employee Target Variable Comp",
            action: "Clicked",
          });

          launch();
        };

        return buttonOverrideProps != null ? (
          <AssembleButton {...buttonOverrideProps} onClick={onClickWrapper} />
        ) : (
          <Button onClick={onClickWrapper} variant="contained" color="primary">
            Upload Target Variable Compensation
          </Button>
        );
      }}
    />
  );
}

function recordValidator(employees: Employee[]) {
  return function validateRecord(record: FlatfileRecord): FlatfileRecord {
    const employee = matchEmployee(record, employees);
    if (employee) {
      if (record.get("employeeNumber") !== employee.employeeNumber) {
        record.set("employeeNumber", employee.employeeNumber);
      }
      if (record.get("email") !== employee.email) {
        record.set("email", employee.email);
      }
      if (record.get("employeeId") !== employee.id) {
        record.set("employeeId", employee.id);
      }
    }
    validateDate(record, "activeAt");
    infoAboutPercentages(record);

    return record;
  };
}

/**
 * Warn the user that we don't concert decimals between 0 and 1 to a percentage.
 */
export function infoAboutPercentages(record: FlatfileRecord): void {
  const stringValue = String(record.get("variableCompValue"));
  const value = parseFloat(stringValue);
  const isPercentage = record.get("compUnit") === "Percent of Salary";

  if (!isPercentage || isNaN(value) || value >= 1) return;

  if (value < 1 && value > 0) {
    const message =
      `Be aware that we do not convert decimals between 0 and ` +
      `1 to a percentage, e.g. '${stringValue}' does not equal ` +
      `${value * 100}%. Instead, use '${value * 100}' to represent that.`;

    record.addWarning("variableCompValue", message);

    return;
  }
  return;
}

function parseData(
  validData: {
    variableCompType: string;
    compUnit: string;
    variableCompValue: string;
    currency: string;
    activeAt: string;
  }[],
  employees: Employee[]
): ImportVariableCompInput[] {
  return validData.map((row) => {
    const record = toFFRecordLike(row);
    const employee = matchEmployee(record, employees);
    const employeeId = employee?.id;

    if (employeeId == null) {
      throw new Error("Employee Not Found");
    }

    const type = {
      Commission: VariableCompType.COMMISSION,
      "Recurring Bonus": VariableCompType.RECURRING_BONUS,
    }[row.variableCompType];

    if (type === undefined) {
      throw new Error("Variable Compensation Type Not Found");
    }

    const unit = {
      "Percent of Salary": CompUnit.PERCENT_OF_SALARY,
      "Cash Value": CompUnit.CASH,
    }[row.compUnit];
    if (unit === undefined) {
      throw new Error("Variable Compensation Unit Not Found");
    }

    return {
      employeeId,
      type,
      unit,
      activeAt: formatDate(new Date(row.activeAt)),
      value: parseFloat(row.variableCompValue),
      currency: row.currency as CurrencyCode,
    };
  });
}

/**
 * Returns a date formatted as YYYY-MM-DD
 */
function formatDate(date: Date): string {
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1; // getUTCMonth is zero-indexed
  const day = date.getUTCDate();

  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;

  return [year, formattedMonth, formattedDay].join("-");
}

VariableCompImportButton.fragments = {
  employee: gql`
    fragment VariableCompImportButton_employee on Employee {
      id
      employeeNumber
      email
      personalEmail
    }
  `,
};
