import { gql } from "@apollo/client";
import { FlatfileRecord } from "@flatfile/hooks";
import { Button, makeStyles } from "@material-ui/core";
import { AssembleFlatfileButton } from "src/components/AssembleFlatfileButton";
import { FlatfileDataResponse } from "src/types/flatfileTypes";
import { flatFileDataToValues, recordHasValue } from "src/utils";
import { EquityVestingImportButton_employee as Employee } from "../../../__generated__/graphql";
import { useTrack } from "../../../analytics";
import { matchEmployee } from "../../../models/csv/EmployeeCSV";

import { PURPLE_1 } from "../../../theme";
import {
  EQUITY_VESTING_FLATFILE_SETTINGS,
  getEquityVestingFlatfileValidators,
} from "./flatfileConfig";

const useStyles = makeStyles(() => ({
  textButton: {
    color: PURPLE_1,
    padding: 0,
  },
}));

export type EquityGrantVestingEventRow = {
  grantName: string;
  vestingDate: string;
  unitCount: string;
  employeeId: string;
};

export interface Props {
  employees: Employee[];
  lastUploadDate: GraphQL_DateTime | null;
  disabled: boolean;
  handleChange: (data: Array<EquityGrantVestingEventRow>) => unknown;
  renderAsLink?: boolean;
  isDialogButton?: boolean;
}

export function EquityVestingImportButton({
  employees,
  disabled,
  lastUploadDate,
  renderAsLink = false,
  handleChange,
  isDialogButton = false,
}: Props): JSX.Element {
  const { trackEvent } = useTrack();
  const recordValidator = (record: FlatfileRecord) =>
    validateRecord(record, employees);

  const classes = useStyles();

  return (
    <AssembleFlatfileButton
      settings={EQUITY_VESTING_FLATFILE_SETTINGS}
      validators={getEquityVestingFlatfileValidators()}
      onRecordChange={recordValidator}
      onData={async ({ sheet }) => {
        try {
          if (sheet == null) {
            throw new Error("Sheet not found");
          }
          const data: FlatfileDataResponse = await sheet.validData();
          handleChange(flatFileDataToValues<EquityGrantVestingEventRow>(data));
          return Promise.resolve();
        } catch (e) {
          return Promise.reject(
            `An error occurred: ${e as string}. Please try again.`
          );
        }
      }}
      render={(launch) => {
        return renderAsLink ? (
          <Button
            onClick={() => {
              trackEvent({
                area: "Data Management",
                object: "Import Employee Vesting Events Button",
                action: "Clicked",
                uploadType: "Import",
              });
              launch();
            }}
            variant="text"
            className={classes.textButton}
          >
            Import equity vesting details.
          </Button>
        ) : (
          <Button
            onClick={() => {
              trackEvent({
                area: isDialogButton ? "Data Management" : "Employee Portal",
                subArea: !isDialogButton ? "Portal Access" : undefined,
                object: "Import Employee Vesting Events Button",
                action: "Clicked",
                uploadType: lastUploadDate == null ? "Import" : "Update",
              });
              launch();
            }}
            variant="contained"
            color={lastUploadDate === null ? "primary" : undefined}
            disabled={disabled}
          >
            {lastUploadDate === null
              ? "Import Equity Vesting"
              : "Update Equity Vesting"}
          </Button>
        );
      }}
    />
  );
}

// Combine all of our validators together into a single response object.
// Beware: validators can overwrite each other, so try to keep fields separate!
function validateRecord(
  record: FlatfileRecord,
  employees: Employee[]
): FlatfileRecord {
  const employee = matchEmployee(record, employees);
  matchEquityGrant(record, employee);
  return record;
}

function matchEquityGrant(
  record: FlatfileRecord,
  employee: Employee | null
): { id: number; name: string; unitCount: number } | null {
  if (!employee || !recordHasValue(record.get("grantName"))) {
    return null;
  }

  const grantMatch = employee.equityHoldings.grants?.find(
    (grant) => grant.name === record.get("grantName")
  );

  if (
    grantMatch &&
    recordHasValue(record.get("unitCount")) &&
    grantMatch.unitCount < parseInt(String(record.get("unitCount") ?? ""), 10)
  ) {
    record.addError(
      "unitCount",
      `The vesting unit count exceeds the total unit count (${grantMatch.unitCount}) for this grant.`
    );
    return null;
  }

  if (!grantMatch) {
    record.addError(
      "grantName",
      "No matching grant was found for this employee."
    );
    return null;
  }

  return grantMatch;
}

EquityVestingImportButton.fragments = {
  employee: gql`
    fragment EquityVestingImportButton_employee on Employee {
      id
      employeeNumber
      email
      personalEmail
      equityHoldings {
        id
        grants {
          id
          name
          unitCount
        }
      }
    }
  `,
  organization: gql`
    fragment EquityVestingImportButton_organization on Organization {
      id
      lastEquityVestingEventsUploadDate
    }
  `,
};
