import { gql } from "@apollo/client";
import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { ActualVariableCompImportButton_employee as Employee } from "src/__generated__/graphql";
import { useTrack } from "src/analytics";
import {
  AssembleButton,
  AssembleButtonProps,
} from "src/components/AssembleButton/AssembleButton";
import { AssembleFlatfileButton } from "src/components/AssembleFlatfileButton";
import { useCurrencies } from "src/components/CurrenciesContext";
import { CurrencyField, EmployeeIdentifierFields } from "src/models/csv/Fields";
import {
  ActualVariableCompFields,
  getActualVariableCompValidators,
} from "src/models/csv/Fields/ActualVariableCompFields";
import { getEmployeeIdentifierValidators } from "src/models/csv/Fields/EmployeeIdentifierFields";
import { useImportActualVariableComp } from "src/mutations/ActualVariableCompensation";
import { noop } from "src/test-helpers";
import { flatFileDataToValues } from "src/utils";
import { parseData, recordValidator } from "./util";

type Props = {
  employees: Employee[];
  onCancel?: () => unknown;
  onUpload?: () => unknown;
  buttonOverrideProps?: AssembleButtonProps;
};

export function ActualVariableCompImportButton({
  employees,
  onCancel,
  onUpload = noop,
  buttonOverrideProps,
}: Props): JSX.Element {
  const validateRecord = recordValidator(employees);
  const { enqueueSnackbar } = useSnackbar();
  const { trackEvent } = useTrack();
  const { currencies } = useCurrencies();

  const upload = useImportActualVariableComp();

  return (
    <AssembleFlatfileButton
      settings={{
        name: "Add your Actual Variable Compensation Data",
        sheets: [
          {
            name: "Add your Actual Variable Compensation Data",
            slug: "actual_variable_comp",
            fields: [
              ...EmployeeIdentifierFields({ allowDuplicates: true }),
              ...ActualVariableCompFields,
              ...CurrencyField(currencies),
            ],
          },
        ],
      }}
      validators={[
        ...getEmployeeIdentifierValidators(),
        ...getActualVariableCompValidators(),
      ]}
      onRecordChange={validateRecord}
      onData={async ({ sheet }) => {
        if (sheet == null) {
          throw new Error("Sheet Not Found");
        }
        const data = await sheet.validData();
        const result = await upload(
          parseData(flatFileDataToValues(data), employees)
        );

        result.data.importActualVariableComp.invalid.forEach((validation) => {
          enqueueSnackbar(validation, { variant: "error" });
        });

        trackEvent({
          object: "Employee Target Actual Comp",
          action: "Imported",
          successfulRecordCount:
            result.data.importActualVariableComp.valid.length,
        });

        onUpload();
      }}
      onCancel={onCancel}
      render={(launch) => {
        const onClickWrapper = () => {
          trackEvent({
            object: "Import Employee Actual Variable Comp",

            action: "Clicked",
          });

          launch();
        };

        return buttonOverrideProps != null ? (
          <AssembleButton {...buttonOverrideProps} onClick={onClickWrapper} />
        ) : (
          <Button onClick={onClickWrapper} variant="contained" color="primary">
            Upload Actual Variable Compensation
          </Button>
        );
      }}
    />
  );
}

ActualVariableCompImportButton.fragments = {
  employee: gql`
    fragment ActualVariableCompImportButton_employee on Employee {
      id
      employeeNumber
      email
      personalEmail
    }
  `,
};
