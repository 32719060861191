import { Property } from "@flatfile/api/api";
import { FFValidator } from "src/utils";
import { INTEGER_VALIDATOR } from "./Validators";

export const TargetRecurringBonusFields: Property[] = [
  {
    label: "Target Rec. Bonus ($)",
    key: "targetRecurringBonus",
    type: "number",
  },
  {
    label: "Target Rec. Bonus (% of Salary)",
    key: "targetRecurringBonusPercent",
    type: "number",
  },
  {
    label: "Target Rec. Bonus Notes",
    key: "targetRecurringBonusNotes",
    type: "string",
  },
];

export const getTargetRecurringBonusValidators = () => [
  FFValidator.regexMatches(
    "targetRecurringBonus",
    RegExp(INTEGER_VALIDATOR),
    "Must be a whole number"
  ),
];
