import * as yup from "yup";
import { Asserts } from "yup";
import { CurrencyCode } from "./constants";

// We have to maintain our own string <> enum mappings until Merge's API provides them
export enum EmploymentStatusEnum {
  Active = "ACTIVE",
  Pending = "PENDING",
  Inactive = "INACTIVE",
}
export enum GenderEnum {
  Male = "MALE",
  Female = "FEMALE",
  NonBinary = "NON_BINARY",
  Other = "OTHER",
  PreferNotToDisclose = "PREFER_NOT_TO_DISCLOSE",
}
export enum EthnicityEnum {
  AmericanIndianOrAlaskaNative = "AMERICAN_INDIAN_OR_ALASKA_NATIVE",
  AsianOrIndianSubcontinent = "ASIAN_OR_INDIAN_SUBCONTINENT",
  BlackOrAfricanAmerican = "BLACK_OR_AFRICAN_AMERICAN",
  HispanicOrLatino = "HISPANIC_OR_LATINO",
  NativeHawaiianOrOtherPacificIslander = "NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER",
  TwoOrMoreRaces = "TWO_OR_MORE_RACES",
  White = "WHITE",
  PreferNotToDisclose = "PREFER_NOT_TO_DISCLOSE",
}
export enum MaritalStatusEnum {
  Single = "SINGLE",
  MarriedFilingJointly = "MARRIED_FILING_JOINTLY",
  MarriedFilingSeparately = "MARRIED_FILING_SEPARATELY",
  HeadOfHousehold = "HEAD_OF_HOUSEHOLD",
  QualifyingWidowOrWidowerWithDependentChild = "QUALIFYING_WIDOW_OR_WIDOWER_WITH_DEPENDENT_CHILD",
}
export enum PayPeriodEnum {
  Hour = "HOUR",
  Day = "DAY",
  Week = "WEEK",
  EveryTwoWeeks = "EVERY_TWO_WEEKS",
  Month = "MONTH",
  Quarter = "QUARTER",
  EverySixMonths = "EVERY_SIX_MONTHS",
  Year = "YEAR",
  Salary = "SALARY", // known passthrough value from Sapling
}
export enum PayFrequencyEnum {
  Weekly = "WEEKLY",
  Biweekly = "BIWEEKLY",
  Monthly = "MONTHLY",
  Quarterly = "QUARTERLY",
  Semiannually = "SEMIANNUALLY",
  Annually = "ANNUALLY",
  ThirteenMonthly = "THIRTEEN-MONTHLY",
  ProRata = "PRO_RATA",
  Null = "NULL",
}
export enum EmploymentTypeEnum {
  FullTime = "FULL_TIME",
  PartTime = "PART_TIME",
  Intern = "INTERN",
  Contractor = "CONTRACTOR",
  Freelance = "FREELANCE",
}

export type MergeImportEmployee = Asserts<typeof mergeEmployeeSchema>;
export type MergeImportEmployment = Asserts<typeof mergeEmploymentSchema>;
export type MergeImportManager = Asserts<typeof mergeManagerSchema>;
export type MergeImportLocation = Asserts<typeof mergeLocationSchema>;
export type MergeImportTeam = Asserts<typeof mergeTeamSchema>;
export type MergeValidEmployment = Asserts<typeof mergeValidEmploymentSchema>;
export type MergeImportCandidate = Asserts<typeof mergeCandidateSchema>;
export type MergeOrganizationFieldMappings = Asserts<
  typeof mergeEmploymentFieldMappingsSchema
>;

const disallowedEmploymentTypeStrings = [
  EmploymentTypeEnum.PartTime.toString(),
  EmploymentTypeEnum.Intern.toString(),
  EmploymentTypeEnum.Contractor.toString(),
  EmploymentTypeEnum.Freelance.toString(),
];
const disallowedEmploymentTypeRegex = new RegExp(
  disallowedEmploymentTypeStrings.join("|")
);

export const isValidEmploymentType = (value: string | undefined): boolean => {
  if (value === undefined) {
    return true;
  }

  if (disallowedEmploymentTypeRegex.test(value)) {
    return false;
  }

  return true;
};

// these are custom field mappings, so they do not follow the camelCase naming convention
const mergeEmploymentTargetsSchema = yup
  .object({
    pay_rate: yup
      .object({
        value: yup.string().notRequired(),
      })
      .notRequired(),
    pay_currency: yup
      .object({
        value: yup
          .mixed<CurrencyCode>()
          .oneOf(Object.values(CurrencyCode))
          .nullable(),
      })
      .notRequired(),
    effective_date: yup
      .object({
        value: yup.date().notRequired(),
      })
      .notRequired(),
  })
  .notRequired();

const mergeEmploymentFieldMappingsSchema = yup
  .object({
    organization_defined_targets: mergeEmploymentTargetsSchema,
    linked_account_defined_targets: mergeEmploymentTargetsSchema,
  })
  .notRequired();

const mergeEmploymentSchema = yup.object({
  id: yup.string().required(),
  jobTitle: yup.string().nullable(),
  payRate: yup.number().nullable(),
  payPeriod: yup.string().nullable(),
  payFrequency: yup.string().nullable(),
  payCurrency: yup.string().nullable(),
  flsaStatus: yup.string().nullable(),
  effectiveDate: yup.date().nullable(),
  employmentType: yup.string().nullable(),
  fieldMappings: mergeEmploymentFieldMappingsSchema,
});

export const mergeValidEmploymentSchema = yup.object({
  id: yup.string().required(),
  jobTitle: yup.string().nullable(),
  payRate: yup.number().required(),
  payPeriod: yup
    .string()
    .notOneOf(
      // Reject anything that cannot be made Yearly, or unknown
      Object.values([
        PayPeriodEnum.Day,
        PayPeriodEnum.Week,
        PayPeriodEnum.EveryTwoWeeks,
        // PayPeriodEnum.Hour,
        // PayPeriodEnum.Month,
        // PayPeriodEnum.Quarter,
        // PayPeriodEnum.EverySixMonths,
        // PayPeriodEnum.Year,
        // PayPeriodEnum.Salary,
      ])
    )
    .nullable(),
  payFrequency: yup.string().nullable(),
  payCurrency: yup
    .mixed<CurrencyCode>()
    .oneOf(Object.values(CurrencyCode))
    .nullable(),
  flsaStatus: yup.string().nullable(),
  effectiveDate: yup.date().required(),
  employmentType: yup
    .string()
    .test(
      "test-allowed-employment-type",
      "${path} matches a disallowed employment type",
      (value) => isValidEmploymentType(value)
    )
    .nullable(),
  fieldMappings: mergeEmploymentFieldMappingsSchema,
});

const mergeLocationSchema = yup.object({
  id: yup.string().required(),
  city: yup.string().nullable(),
  state: yup.string().nullable(),
  country: yup.string().nullable(),
  zipCode: yup.string().nullable(),
});

const mergeManagerSchema = yup.object({
  id: yup.string().required(),
  firstName: yup.string().nullable(),
  lastName: yup.string().nullable(),
  displayFullName: yup.string().nullable(),
  workEmail: yup.string().email().lowercase().nullable(),
  personalEmail: yup.string().email().lowercase().nullable(),
});

const mergeTeamSchema = yup.object({
  id: yup.string().required(),
  name: yup.string().nullable(),
});

// these are in snake_case as recommended in the Merge docs
const mergeCustomFieldSchema = yup.object({
  leveling_code: yup.string().notRequired(),
  hris_custom_city: yup.string().notRequired(),
});

// hris_custom_city allows customers to define their own custom city mapping,
// as some want to define their own to account for certain zone groupings
// and merge doesn't allow field mapping overrides of common model fields
// these are in snake_case as recommended in the Merge docs
const mergeEmployeeTargetsSchema = yup
  .object({
    hris_custom_city: yup
      .object({
        value: yup.string().notRequired().default(null),
      })
      .notRequired()
      .default(null),
  })
  .notRequired()
  .default(null);

const mergeEmployeeFieldMappingsSchema = yup
  .object({
    organization_defined_targets: mergeEmployeeTargetsSchema,
  })
  .notRequired()
  .default(null);

export const mergeEmployeeSchema = yup.object({
  id: yup.string().required(),
  employeeNumber: yup.string().nullable(),
  firstName: yup.string().nullable(),
  lastName: yup.string().nullable(),
  displayFullName: yup.string().nullable(),
  workEmail: yup.string().email().lowercase().required(),
  personalEmail: yup.string().email().lowercase().nullable(),
  employments: yup.array().of(mergeEmploymentSchema).required(),
  homeLocation: mergeLocationSchema.nullable().default(null),
  workLocation: mergeLocationSchema.nullable().default(null),
  manager: mergeManagerSchema.nullable().default(null),
  team: mergeTeamSchema.nullable().default(null),
  gender: yup.string().nullable(),
  ethnicity: yup.string().nullable(),
  maritalStatus: yup.string().nullable(),
  dateOfBirth: yup.date().nullable(),
  startDate: yup.date().nullable(),
  employmentStatus: yup.string().nullable(),
  terminationDate: yup.date().nullable(),
  customFields: mergeCustomFieldSchema.notRequired(),
  fieldMappings: mergeEmployeeFieldMappingsSchema.notRequired().default(null),
});

const mergeEmail = yup.object({
  value: yup.string().lowercase().required(),
});

const mergeApplication = yup.object({
  id: yup.string().required(),
  job: yup.string(),
  rejectedAt: yup.date(),
});

export const mergeCandidateSchema = yup.object({
  id: yup.string().required(),
  firstName: yup.string().nullable(),
  lastName: yup.string().nullable(),
  emailAddresses: yup.array().of(mergeEmail).required(),
  applications: yup.array().of(mergeApplication).required(),
});
