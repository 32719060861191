import { CreateWorkbookConfig, Property } from "@flatfile/api/api";
import { CompStructure } from "src/__generated__/graphql";

export const getFlatfileDataUpload = (
  compStructure: CompStructure
): CreateWorkbookConfig => {
  const uploadFields = compStructure.allBandTypes.flatMap((bandType) => {
    return compStructure.bandPointTypes.map((bandPoint) => {
      return `${bandType} - ${bandPoint}`;
    });
  });

  return {
    name: "Upload Data",
    sheets: [
      {
        name: "Upload Data",
        slug: "Upload Data",

        fields: [
          {
            label: "Position",
            key: "position",
            type: "string",
          },
          {
            label: "Level",
            key: "level",
            type: "number",
          },
          {
            label: "Job Code",
            key: "jobCode",
            type: "string",
          },
          {
            label: "Currency Code",
            key: "currencyCode",
            type: "string",
            constraints: [{ type: "required" }],
          },
          ...uploadFields.map(
            (uploadField) =>
              ({
                label: uploadField,
                key: uploadField,
                type: "string",
              }) as Property
          ),
        ],
      },
    ],
  };
};
