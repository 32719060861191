import { gql } from "@apollo/client";
import { FlatfileRecord } from "@flatfile/hooks";
import {
  EmployeeIdentifierFields,
  getEmployeeIdentifierValidators,
} from "src/models/csv/Fields/EmployeeIdentifierFields";
import { flatFileDataToValues, toFFRecordLike } from "src/utils";
import {
  UploadLevelsButton_employee as Employee,
  LevelEmployeesInput,
  UploadLevelsButton_position as Position,
} from "../__generated__/graphql";
import { matchEmployee } from "../models/csv/EmployeeCSV";
import {
  getPositionFields,
  getPositionValidators,
  matchPosition,
} from "../models/csv/PositionCSV";
import { useLevelEmployees } from "../mutations/Employments";
import { AssembleButton } from "./AssembleButton/AssembleButton";
import { AssembleFlatfileButton } from "./AssembleFlatfileButton";

type Props = {
  employees: Employee[];
  positions: Position[];
};

export function UploadLevelsButton({
  employees,
  positions,
}: Props): JSX.Element {
  const levelEmployees = useLevelEmployees();
  const validateRecord = recordValidator(employees, positions);

  const pos = new Set<string>(positions.map((position) => position.name));

  const departments = new Set<string>(
    positions.map((position) => position.ladder.department.name)
  );

  const ladders = new Set<string>(
    positions.map((position) => position.ladder.name)
  );

  const levels = new Set(positions.map((position) => position.level));

  return (
    <AssembleFlatfileButton
      onRecordChange={validateRecord}
      settings={{
        name: "Add your leveling data",
        sheets: [
          {
            name: "Add your leveling data",
            slug: "Add your leveling data",
            fields: [
              ...EmployeeIdentifierFields(),
              ...getPositionFields(pos, departments, ladders, levels),
            ],
          },
        ],
      }}
      validators={[
        ...getEmployeeIdentifierValidators(),
        ...getPositionValidators(),
      ]}
      onData={async ({ sheet }) => {
        if (sheet == null) {
          throw new Error("Sheet not found");
        }
        const data = await sheet.validData();
        await levelEmployees(
          parseData(flatFileDataToValues(data), employees, positions)
        );

        return;
      }}
      render={(launch) => (
        <AssembleButton
          onClick={launch}
          variant="contained"
          label="Bulk level employees"
          size="medium"
        />
      )}
    />
  );
}

function parseData(
  validData: Record<string, unknown>[],
  employees: Employee[],
  positions: Position[]
): LevelEmployeesInput[] {
  return validData.map((row) => {
    const record = toFFRecordLike(row);
    const position = matchPosition(record, positions);
    const employee = matchEmployee(record, employees);

    const employeeId = employee?.id;
    const positionId = position?.id;

    if (employeeId == null) {
      throw new Error("Employee Not Found");
    }

    if (positionId == null) {
      throw new Error("Position Not Found");
    }

    return {
      employeeId,
      positionId,
    };
  });
}

function recordValidator(employees: Employee[], positions: Position[]) {
  return function validateRecord(record: FlatfileRecord): FlatfileRecord {
    matchEmployee(record, employees);
    matchPosition(record, positions);
    return record;
  };
}

UploadLevelsButton.fragments = {
  organization: gql`
    fragment UploadLevelsButton_organization on Organization {
      id
      name
    }
  `,
  employee: gql`
    fragment UploadLevelsButton_employee on Employee {
      id
      employeeNumber
      email
      personalEmail
    }
  `,
  position: gql`
    fragment UploadLevelsButton_position on Position {
      id
      name
      level
      ladder {
        id
        name
        department {
          id
          name
        }
      }
    }
  `,
};
