import { Property } from "@flatfile/api/api";
import { FFValidator } from "src/utils";
import { INTEGER_VALIDATOR } from "./Validators";

export const MarketAdjustmentFields: Property[] = [
  {
    label: "Market Adjustment ($)",
    key: "salaryMarket",
    type: "number",
  },
  {
    label: "Market Adjustment (%)",
    key: "salaryMarketPercent",
    type: "number",
  },
  {
    label: "Market Notes",
    key: "salaryMarketNotes",
    type: "string",
  },
];

export const getMarketAdjustmentValidators = () => [
  FFValidator.regexMatches(
    "salaryMarket",
    RegExp(INTEGER_VALIDATOR),
    "Must be a whole number"
  ),
];
