import { gql } from "@apollo/client";
import { DEFAULT_USD } from "@asmbl/shared/constants";
import { isEmptyString } from "@asmbl/shared/utils";
import { FlatfileRecord, TRecordValue } from "@flatfile/hooks";

import { Button } from "@material-ui/core";
import { AssembleFlatfileButton } from "src/components/AssembleFlatfileButton";
import { FlatfileDataResponse } from "src/types/flatfileTypes";
import { flatFileDataToValues } from "src/utils";
import { EquityImportButton_employee as Employee } from "../../../__generated__/graphql";
import { useTrack } from "../../../analytics";
import { matchEmployee } from "../../../models/csv/EmployeeCSV";
import {
  EQUITY_FLATFILE_SETTINGS,
  getEquityFlatfileValidators,
} from "./flatfileConfig";

export type EmployeeEquityGrantRow = {
  employeeId: number;
  grantName: string;
  equityType: string;
  unitCount: string;
  price: string;
  priceCurrencyCode: string;
  issueDate: string;
  vestingStartDate: string;
  vestingScheduleDescription: string;
  awardType: string;
};

interface Props {
  employees: Employee[];
  handleChange: (data: Array<EmployeeEquityGrantRow>) => unknown;
  handleCancel?: () => unknown;
  lastUploadDate?: GraphQL_DateTime | null | undefined;
  isDialogButton?: boolean;
}

export function EquityImportButton({
  employees,
  handleChange,
  handleCancel,
  lastUploadDate,
  isDialogButton = false,
}: Props): JSX.Element {
  const { trackEvent } = useTrack();
  const validateRecord = recordValidator(employees);

  return (
    <AssembleFlatfileButton
      settings={EQUITY_FLATFILE_SETTINGS}
      validators={getEquityFlatfileValidators()}
      onRecordChange={validateRecord}
      onData={async ({ sheet }) => {
        try {
          if (sheet == null) {
            throw new Error("Sheet not found");
          }
          const data: FlatfileDataResponse = await sheet.validData();
          handleChange(flatFileDataToValues<EmployeeEquityGrantRow>(data));
          return Promise.resolve();
        } catch (e) {
          return Promise.reject(
            `An error occurred: ${e as string}. Please try again.`
          );
        }
      }}
      onCancel={handleCancel}
      render={(launch) => (
        <Button
          onClick={() => {
            trackEvent({
              area: isDialogButton ? "Data Management" : "Employee Portal",
              subArea: !isDialogButton ? "Portal Access" : undefined,
              object: "Import Employee Grants Button",
              action: "Clicked",
              uploadType: lastUploadDate == null ? "Import" : "Update",
            });
            launch();
          }}
          variant="contained"
          color={lastUploadDate == null ? "primary" : undefined}
        >
          {lastUploadDate == null
            ? "Import Employee Equity"
            : "Update Employee Equity"}
        </Button>
      )}
    />
  );
}

// Combine all of our validators together into a single response object.
// Beware: validators can overwrite each other, so try to keep fields separate!
function recordValidator(employees: Employee[]) {
  return function validateRecord(record: FlatfileRecord): FlatfileRecord {
    matchEmployee(record, employees);

    const priceCurrencyCodeValue = (
      isEmptyString(
        record.get("priceCurrencyCode") as string | undefined | null
      )
        ? DEFAULT_USD.code
        : record.get("priceCurrencyCode")
    ) as string;

    // Set the priceCurrencyCode field on the record
    record.set("priceCurrencyCode", priceCurrencyCodeValue as TRecordValue);

    return record;
  };
}

EquityImportButton.fragments = {
  employee: gql`
    fragment EquityImportButton_employee on Employee {
      id
      employeeNumber
      email
      personalEmail
    }
  `,
};
