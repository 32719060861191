/* eslint-disable @typescript-eslint/no-base-to-string */
import { Property } from "@flatfile/api/api";
import { FlatfileRecord } from "@flatfile/hooks";
import { toInteger } from "lodash";
import { FFValidator, recordHasValue } from "src/utils";

type Position = {
  id: number;
  name: string;
  level: number;
  ladder: {
    id: number;
    name: string;
    department: {
      id: number;
      name: string;
    };
  };
};

export function getPositionFields(
  positions: Set<string>,
  departments: Set<string>,
  ladders: Set<string>,
  levels: Set<number>
): Property[] {
  return [
    {
      label: "Position ID",
      key: "positionId",
      type: "number",
    },
    {
      label: "Department",
      key: "departmentName",
      type: "enum",
      config: {
        options: Array.from(departments.values()).map((name) => ({
          label: name,
          value: name,
        })),
      },
    },
    {
      label: "Ladder",
      key: "ladderName",
      type: "enum",
      config: {
        options: Array.from(ladders.values()).map((name) => ({
          label: name,
          value: name,
        })),
      },
    },
    {
      label: "Level",
      key: "levelNumber",
      type: "enum",
      config: {
        options: Array.from(levels).map((name) => ({
          label: name.toString(),
          value: name,
        })),
      },
    },
    {
      label: "Position",
      key: "positionName",
      type: "enum",
      config: {
        options: Array.from(positions.values()).map((name) => ({
          label: name,
          value: name,
        })),
      },
    },
  ];
}

export const getPositionValidators = () => [
  FFValidator.requiredWithoutAll("positionId", [
    "departmentName",
    "ladderName",
    "levelNumber",
    "positionName",
  ]),
  FFValidator.requiredWithAll("departmentName", [
    "ladderName",
    "levelNumber",
    "positionName",
  ]),
  FFValidator.requiredWithAll("ladderName", [
    "departmentName",
    "levelNumber",
    "positionName",
  ]),
  FFValidator.requiredWithAll("levelNumber", [
    "departmentName",
    "ladderName",
    "positionName",
  ]),
  FFValidator.requiredWithAll("positionName", [
    "departmentName",
    "ladderName",
    "levelNumber",
  ]),
];

function positionIdAndPositionByPartsPresent(field: string): string {
  return `Position ID and ${field} are specified. This value will be ignored.`;
}

export function matchPosition<P extends Position>(
  record: FlatfileRecord,
  positions: P[]
): P | null {
  const positionId = recordHasValue(record.get("positionId"))
    ? toInteger(record.get("positionId"))
    : null;

  const departmentName = recordHasValue(record.get("departmentName"))
    ? record.get("departmentName")
    : null;

  const ladderName = recordHasValue(record.get("ladderName"))
    ? record.get("ladderName")
    : null;

  const levelNumber = recordHasValue(record.get("levelNumber"))
    ? toInteger(record.get("levelNumber"))
    : null;

  const positionName = recordHasValue(record.get("positionName"))
    ? record.get("positionName")
    : null;

  const positionByParts =
    departmentName != null &&
    ladderName != null &&
    levelNumber != null &&
    positionName != null;

  const anyPart =
    departmentName != null ||
    ladderName != null ||
    levelNumber != null ||
    positionName != null;

  if (positionId != null) {
    const positionById = positions.find(
      (position) => position.id === positionId
    );

    if (positionById && anyPart) {
      record.addInfo("positionId", `Position matched: ${positionById.name}.`);
      if (departmentName != null) {
        record.addWarning(
          "departmentName",
          positionIdAndPositionByPartsPresent("Department")
        );
      }
      if (ladderName != null) {
        record.addWarning(
          "ladderName",
          positionIdAndPositionByPartsPresent("Ladder")
        );
      }
      if (levelNumber != null) {
        record.addWarning(
          "levelNumber",
          positionIdAndPositionByPartsPresent("Level")
        );
      }
      if (positionName != null) {
        record.addWarning(
          "positionName",
          positionIdAndPositionByPartsPresent("Position (name)")
        );
      }
      return positionById;
    }

    if (positionById) {
      record.addInfo("positionId", `Position matched: ${positionById.name}.`);
      return positionById;
    }

    record.addError("positionId", "No matching Position found.");

    return null;
  }

  if (positionByParts) {
    // try to find a matching position with all the parts

    const matchingPosition = positions
      .filter(({ level }) => level === levelNumber)
      .filter(({ ladder }) => ladder.name === ladderName)
      .filter(({ ladder }) => ladder.department.name === departmentName)
      .find((position) => position.name === positionName);

    if (matchingPosition) {
      return matchingPosition;
    } else {
      record.addError("departmentName", "No matching Position found.");
      record.addError("ladderName", "No matching Position found.");
      record.addError("levelNumber", "No matching Position found.");
      record.addError("positionName", "No matching Position found.");
      return null;
    }
  } else if (anyPart) {
    record.addError(
      "departmentName",
      "Department, Ladder, Level, and Position are required if you are not using Position ID."
    );
    record.addError(
      "ladderName",
      "Department, Ladder, Level, and Position are required if you are not using Position ID."
    );
    record.addError(
      "levelNumber",
      "Department, Ladder, Level, and Position are required if you are not using Position ID."
    );
    record.addError(
      "positionName",
      "Department, Ladder, Level, and Position are required if you are not using Position ID."
    );
    return null;
  }

  record.addError("positionId", "No matching Position found.");
  record.addError("departmentName", "No matching Position found.");
  record.addError("ladderName", "No matching Position found.");
  record.addError("levelNumber", "No matching Position found.");
  record.addError("positionName", "No matching Position found.");
  return null;
}
