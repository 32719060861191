import { makeStyles } from "@material-ui/core";
import { AssembleFlatfileButton } from "src/components/AssembleFlatfileButton";
import { flatFileDataToValues } from "src/utils";
import { AssembleButton } from "../../AssembleButton/AssembleButton";

export type EmployeePerfScoreRow = {
  email: string;
  note?: string;
  perfRating: string;
};

const useStyles = makeStyles(() => ({
  cardButton: {
    left: "50%",
    position: "absolute",
    top: "-52px",
    translate: "-50% 0",
  },
}));

interface Props {
  handleChange: (data: Array<EmployeePerfScoreRow>) => unknown;
  handleCancel?: () => unknown;
}

export function PerfImportButton({
  handleChange,
  handleCancel,
}: Props): JSX.Element {
  const classes = useStyles();
  return (
    <AssembleFlatfileButton
      settings={{
        name: "Add your performance scores",
        sheets: [
          {
            name: "EmployeePerfScoreRow",
            slug: "employee-perf-score-row",
            fields: [
              { label: "Name", key: "displayName", type: "string" },
              {
                label: "Email",
                key: "email",
                type: "string",
                constraints: [{ type: "required" }, { type: "unique" }],
              },
              {
                label: "Perf. Score",
                key: "perfRating",
                type: "number",
                constraints: [{ type: "required" }],
              },
              { label: "Note/Details", key: "note", type: "string" },
            ],
          },
        ],
      }}
      onData={async ({ sheet }) => {
        if (!sheet) {
          throw new Error("No sheet found");
        }
        const data = await sheet.validData();
        handleChange(flatFileDataToValues<EmployeePerfScoreRow>(data));
        return new Promise((resolve) => setTimeout(resolve, 2000));
      }}
      onCancel={handleCancel}
      render={(launch) => (
        <AssembleButton
          onClick={launch}
          variant="contained"
          label="Import Your Ratings"
          size="medium"
          className={classes.cardButton}
        />
      )}
    />
  );
}
