import { Property } from "@flatfile/api/api";
import { FFValidator } from "src/utils";

export const VariableCompIdentifierFields: Property[] = [
  {
    label: "Active At",
    description:
      "The date that this compensation became active.\nFormat: YYYY-MM-DD",
    key: "activeAt",
    type: "date",
    constraints: [{ type: "required" }],
  },
  {
    label: "Type",
    key: "variableCompType",
    description: "The type of variable comp you want to upload.",
    type: "enum",
    config: {
      options: ["Commission", "Recurring Bonus"].map((code) => ({
        label: code,
        value: code,
      })),
    },
    constraints: [{ type: "required" }],
  },
  {
    label: "Unit",
    key: "compUnit",
    description: "The unit of the variable comp you want to upload.",
    type: "enum",
    config: {
      options: ["Percent of Salary", "Cash Value"].map((code) => ({
        label: code,
        value: code,
      })),
    },
    constraints: [{ type: "required" }],
  },
  {
    label: "Value",
    key: "variableCompValue",
    description: "The value of the variable comp you want to upload.",
    type: "number",
    constraints: [{ type: "required" }],
  },
];

export const getVariableCompIdentifierValidators = () => [
  FFValidator.regexMatches(
    "activeAt",
    RegExp("^\\d{4}-\\d{2}-\\d{2}$"),
    "Must be in format YYYY-MM-DD"
  ),
];
