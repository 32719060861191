import { Property } from "@flatfile/api/api";
import { FFValidator } from "src/utils";

export const ActualVariableCompFields: Property[] = [
  {
    label: "Payment Date",
    description: "The date that this was paid out.\nFormat: YYYY-MM-DD",
    key: "paymentDate",
    type: "date",
    constraints: [{ type: "required" }],
  },
  {
    label: "Type",
    key: "type",
    description:
      "The type of variable comp you want to upload.\nMust be one of 'COMMISSION' or 'BONUS'",
    type: "enum",
    config: {
      options: ["COMMISSION", "BONUS"].map((code) => ({
        label: code,
        value: code,
      })),
    },
    constraints: [{ type: "required" }],
  },
  {
    label: "Subtype",
    key: "subType",
    description: "The subtype of variable comp you want to upload.",
    type: "string",
  },
  {
    label: "Value",
    key: "value",
    description: "The value of the variable comp you want to upload.",
    type: "number",
    constraints: [{ type: "required" }],
  },
];

export const getActualVariableCompValidators = () => [
  FFValidator.regexMatches(
    "paymentDate",
    RegExp("^\\d{4}-\\d{2}-\\d{2}$"),
    "Must be in format YYYY-MM-DD"
  ),
];
