import { CurrencyCode } from "@asmbl/shared/constants";
import { Currency } from "@asmbl/shared/currency";
import { Property } from "@flatfile/api/api";
import { CurrencyField } from "src/models/csv/Fields";

export const MarketDataFields = (
  currencies: Map<CurrencyCode, Currency<CurrencyCode>>
): Property[] => {
  return [
    {
      label: "Job Code",
      key: "jobCode",
      constraints: [{ type: "required" }],
      description: "The job code that identifies this position in Assemble",
      type: "string",
    },
    ...CurrencyField(currencies),
    {
      label: "Company Stage",
      key: "companyStage",
      type: "string",
    },
    {
      label: "Sample Size",
      key: "sampleSize",
      type: "number",
    },
    {
      label: "Salary 10th Percentile",
      key: "salary10",
      type: "number",
    },
    {
      label: "Salary 25th Percentile",
      key: "salary25",
      type: "number",
    },
    {
      label: "Salary 40th Percentile",
      key: "salary40",
      type: "number",
    },
    {
      label: "Salary 50th Percentile",
      key: "salary50",
      type: "number",
    },
    {
      label: "Salary 60th Percentile",
      key: "salary60",
      type: "number",
    },
    {
      label: "Salary 75th Percentile",
      key: "salary75",
      type: "number",
    },
    {
      label: "Salary 90th Percentile",
      key: "salary90",
      type: "number",
    },
    {
      label: "Variable 10th Percentile",
      key: "variable10",
      type: "number",
    },
    {
      label: "Variable 25th Percentile",
      key: "variable25",
      type: "number",
    },
    {
      label: "Variable 40th Percentile",
      key: "variable40",
      type: "number",
    },
    {
      label: "Variable 50th Percentile",
      key: "variable50",
      type: "number",
    },
    {
      label: "Variable 60th Percentile",
      key: "variable60",
      type: "number",
    },
    {
      label: "Variable 75th Percentile",
      key: "variable75",
      type: "number",
    },
    {
      label: "Variable 90th Percentile",
      key: "variable90",
      type: "number",
    },
    {
      label: "Equity 10th Percentile",
      key: "equity10",
      type: "number",
    },
    {
      label: "Equity 25th Percentile",
      key: "equity25",
      type: "number",
    },
    {
      label: "Equity 40th Percentile",
      key: "equity40",
      type: "number",
    },
    {
      label: "Equity 50th Percentile",
      key: "equity50",
      type: "number",
    },
    {
      label: "Equity 60th Percentile",
      key: "equity60",
      type: "number",
    },
    {
      label: "Equity 75th Percentile",
      key: "equity75",
      type: "number",
    },
    {
      label: "Equity 90th Percentile",
      key: "equity90",
      type: "number",
    },
    {
      label: "Total Cash 10th Percentile",
      key: "totalCash10",
      type: "number",
    },
    {
      label: "Total Cash 25th Percentile",
      key: "totalCash25",
      type: "number",
    },
    {
      label: "Total Cash 40th Percentile",
      key: "totalCash40",
      type: "number",
    },
    {
      label: "Total Cash 50th Percentile",
      key: "totalCash50",
      type: "number",
    },
    {
      label: "Total Cash 60th Percentile",
      key: "totalCash60",
      type: "number",
    },
    {
      label: "Total Cash 75th Percentile",
      key: "totalCash75",
      type: "number",
    },
    {
      label: "Total Cash 90th Percentile",
      key: "totalCash90",
      type: "number",
    },
  ];
};
