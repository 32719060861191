import { Constraint, Property } from "@flatfile/api/api";
import { FFValidator } from "src/utils";
import { INTEGER_VALIDATOR } from "./Validators";

export const EmployeeIdentifierFields = (
  options: { allowDuplicates: boolean } = { allowDuplicates: false }
): Property[] => {
  const unique = (
    !options.allowDuplicates ? [{ type: "unique" }] : []
  ) as Constraint[];
  return [
    {
      // Name is not used to identify Employees but it is helpful for debugging
      label: "Employee Name",
      key: "name",
      type: "string",
    },
    {
      label: "Employee ID",
      description:
        "The Employee's unique identifier from your HRIS system. ex: E00123 or 123",
      key: "employeeNumber",
      type: "string",
      constraints: [...unique],
    },
    {
      label: "Employee Email",
      description: "The Employee's work email address",
      key: "email",
      type: "string",
      constraints: [...unique],
    },
    {
      label: "Assemble Employee Key",
      description:
        "Assemble's unique identifier for this employee. You likely want to use Employee ID or Employee Email instead.",
      key: "employeeId",
      type: "string",
      constraints: [...unique],
    },
  ];
};

export const getEmployeeIdentifierValidators = () => [
  FFValidator.requiredWithoutAll("employeeNumber", ["employeeId", "email"]),
  FFValidator.requiredWithoutAll("email", ["employeeId", "employeeNumber"]),
  FFValidator.requiredWithoutAll("employeeId", ["employeeNumber", "email"]),
  FFValidator.regexMatches(
    "employeeId",
    RegExp(INTEGER_VALIDATOR),
    "Assemble Employee ID must be a number"
  ),
];
