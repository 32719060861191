import { gql } from "@apollo/client";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  makeStyles,
  Switch,
  TextField,
  Tooltip,
} from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { SearchIcon } from "src/components/AssembleIcons/Brand/SearchIcon";
import { ErrorColorIcon } from "src/components/AssembleIcons/Small/ErrorColorIcon";
import { LeftAlignIcon } from "src/components/AssembleIcons/Small/LeftAlignIcon";
import { SuccessColorIcon } from "src/components/AssembleIcons/Small/SuccessColorIcon";
import { WarningColorIcon } from "src/components/AssembleIcons/Small/WarningColorIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { ComplexToggle } from "src/components/ComplexToggle";
import { useDetectOS } from "src/hooks/useDetectOS";
import { GRAY_4, GRAY_5, WHITE } from "src/theme";
import { useProgressPageContext } from "./Context/ProgressPageContext";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px 16px",
    backgroundColor: WHITE,
  },
  subContainer: {
    display: "flex",
    alignItems: "center",
  },
  searchInput: {
    borderColor: GRAY_5,
    padding: "0px",
    width: "13.25rem",
    height: "2rem",
    fontSize: "0.875rem",
  },
  searchInputIcon: {
    marginLeft: theme.spacing(1.5),
    padding: "0px",
  },
  smallGap: {
    gap: ".5rem",
  },
  mediumGap: {
    gap: ".75rem",
  },
  largeGap: {
    gap: "2rem",
  },
  toggleOption: {
    display: "flex",
    alignItems: "center",
    gap: ".5rem",
    height: "calc(2rem - 2px)",
  },
  tooltipTextContainer: {
    display: "flex",
    gap: theme.spacing(0.5),
    alignText: "center",
  },
  tooltipCmdText: {
    border: `1px solid ${GRAY_4}`,
    borderRadius: "4px",
    padding: theme.spacing(0, 0.5),
  },
}));

export function ProgressTableHeader() {
  const classes = useStyles();
  const os = useDetectOS();
  const {
    setSearchQuery,
    setCompletionFilter,
    completionFilter,
    setShowSubmissionFlow,
    showSubmissionFlow,
    phases,
    currentPhase,
    handleAdvanceToNextPhase,
  } = useProgressPageContext();
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleButtonClick = async () => {
    setLoading(true);
    await handleAdvanceToNextPhase();
    setTimeout(() => setLoading(false), 1000);
    setDialogOpen(false);
  };

  const disablePhaseAdvancement =
    (currentPhase?.id != null &&
      currentPhase.id === phases[phases.length - 1]?.id) ||
    phases.length === 0;

  const startAdornment = (
    <InputAdornment position="start">
      <SearchIcon color={GRAY_4} className={classes.searchInputIcon} />
    </InputAdornment>
  );

  const currentPhaseOrder = currentPhase?.phaseOrder ?? 0;

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setSearchQuery(e.target.value);

  return (
    <div className={classes.container}>
      <div className={clsx(classes.subContainer, classes.mediumGap)}>
        <TextField
          variant="outlined"
          placeholder="Search people"
          onChange={handleOnChange}
          size="small"
          InputProps={{
            className: classes.searchInput,
            startAdornment: startAdornment,
          }}
        />
        <ComplexToggle
          options={[
            [
              "All",
              <ToggleOption option="All" key="all-toggle">
                <LeftAlignIcon />
              </ToggleOption>,
            ],
            [
              "Submitted",
              <ToggleOption option="Submitted" key="submitted-toggle">
                <SuccessColorIcon />
              </ToggleOption>,
            ],
            [
              "Incomplete",
              <ToggleOption option="Incomplete" key="incomplete-toggle">
                <ErrorColorIcon />
              </ToggleOption>,
            ],
            [
              "Skipped",
              <ToggleOption option="Skipped" key="skipped-toggle">
                <WarningColorIcon />
              </ToggleOption>,
            ],
          ]}
          selected={completionFilter}
          onChange={setCompletionFilter}
          forceUpperCase={false}
        />
      </div>
      <div className={clsx(classes.subContainer, classes.largeGap)}>
        <div className={clsx(classes.subContainer, classes.smallGap)}>
          <AssembleTypography textColor={GRAY_4} variant="productButtonSmall">
            Show submission flow
          </AssembleTypography>
          <Tooltip
            title={
              <div className={classes.tooltipTextContainer}>
                <AssembleTypography variant="productTooltip">
                  To toggle hold
                </AssembleTypography>
                <AssembleTypography
                  variant="productTooltip"
                  textColor={GRAY_4}
                  className={classes.tooltipCmdText}
                >
                  {os === "MacOS" ? "Option" : "Alt"}
                </AssembleTypography>
              </div>
            }
          >
            <Switch
              checked={showSubmissionFlow}
              onChange={setShowSubmissionFlow}
            />
          </Tooltip>
        </div>
        <div className={clsx(classes.subContainer, classes.mediumGap)}>
          <Dialog open={dialogOpen}>
            <DialogTitle>
              Are you sure you want to advance to the next phase?
            </DialogTitle>
            <DialogContent>
              <AssembleTypography>
                Advancing from{" "}
                {currentPhaseOrder === 0
                  ? "Cycle Configuration"
                  : `Phase ${currentPhaseOrder}`}{" "}
                to Phase {currentPhaseOrder + 1} will{" "}
                {currentPhaseOrder === 0
                  ? "open the comp cycle to reviewers."
                  : `prevent anyone in Phase ${currentPhaseOrder} from making any additional requests.`}{" "}
                Are you sure you want to proceed?
              </AssembleTypography>
            </DialogContent>
            <DialogActions>
              <AssembleButton
                variant="outlined"
                label="Go back"
                onClick={() => setDialogOpen(false)}
                size="medium"
              />
              <AssembleButton
                variant="contained"
                label="Proceed"
                onClick={handleButtonClick}
                size="medium"
              />
            </DialogActions>
          </Dialog>
          <AssembleButton
            size="small"
            variant="outlined"
            label="Advance to Next Phase"
            disabled={disablePhaseAdvancement || loading}
            onClick={() => setDialogOpen(true)}
          />
        </div>
      </div>
    </div>
  );
}

function ToggleOption({
  option,
  children,
}: {
  option: string;
  children: JSX.Element;
}): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.toggleOption}>
      <AssembleTypography textColor={GRAY_4} variant="productButtonSmall">
        {option}
      </AssembleTypography>
      {children}
    </div>
  );
}

ProgressTableHeader.fragments = {
  phases: gql`
    fragment ProgressTableHeader_phase on CompCyclePhase2 {
      id
      phaseOrder
      startDate
    }
  `,
};
