import { gql } from "@apollo/client";
import { isEmailRule, Rule, RuleSet } from "@asmbl/shared/eligibility";
import { Button, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { MultiRuleEditor_employee as Employee } from "src/__generated__/graphql";
import { AssembleTypography } from "src/components/AssembleTypography";
import { GRAY_3, PURPLE_1 } from "src/theme";
import { EmailRuleEditor } from "./EmailRuleEditor";
import { RuleEditor } from "./RuleEditor";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttons: {
    display: "flex",
    gap: "1rem",
  },
  emailButton: {
    color: PURPLE_1,
    "&:hover": {
      color: PURPLE_1,
      border: `1px solid ${PURPLE_1}`,
    },
  },
  rule: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  orText: { marginLeft: "1rem" },
}));

type Props = {
  ruleSet: Rule[];
  onChange: (rules: Rule[]) => void;
  employees: Employee[];
  departmentOptions?: { label: string; value: number }[];
  ladderOptions?: { label: string; value: number }[];
  levelOptions?: { label: string; value: number }[];
  perfRatingOptions?: { label: string; value: string }[];
  marketOptions?: { label: string; value: number }[];
  locationGroupOptions?: { label: string; value: number }[];
};

export function MultiRuleEditor({
  ruleSet,
  onChange,
  employees,
  departmentOptions = [],
  ladderOptions = [],
  levelOptions = [],
  perfRatingOptions = [],
  marketOptions = [],
  locationGroupOptions = [],
}: Props): JSX.Element {
  const [rules, setRules] = useState<RuleSet>(ruleSet);
  const classes = useStyles();

  const setRulesAndChange = (newRules: Rule[]) => {
    setRules(newRules);
    onChange(newRules);
  };

  const addRule = () => setRulesAndChange([...rules, []]);

  const handleChange = (index: number) => (newRule: Rule) =>
    setRulesAndChange(rules.map((r, i) => (i === index ? newRule : r)));

  const deleteRule = (index: number) =>
    setRulesAndChange(rules.filter((_, i) => i !== index));

  const addEmailRule = () =>
    setRulesAndChange([
      ...rules,
      [{ alwaysIncludeIds: [] }, { alwaysExcludeIds: [] }],
    ]);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <AssembleTypography variant="productParagraphLarge">
          Employees are <b>eligible</b> if they meet one or more of the rules:
        </AssembleTypography>
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.emailButton}
            onClick={addEmailRule}
            disabled={rules.some(isEmailRule)}
          >
            Filter by Email
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={addRule}
            disabled={isEmpty(rules)}
          >
            + Add Rule
          </Button>
        </div>
      </div>
      {rules.map((r, i) => {
        return (
          <div key={i} className={classes.rule}>
            {isEmailRule(r) ? (
              <EmailRuleEditor
                rule={r}
                onChange={handleChange(i)}
                onDelete={() => deleteRule(i)}
                employees={employees}
              />
            ) : (
              <RuleEditor
                key={i}
                title={`Rule ${i + 1}`}
                rule={r}
                onChange={handleChange(i)}
                onDelete={() => deleteRule(i)}
                departmentOptions={departmentOptions}
                ladderOptions={ladderOptions}
                levelOptions={levelOptions}
                perfRatingOptions={perfRatingOptions}
                marketOptions={marketOptions}
                locationGroupOptions={locationGroupOptions}
              />
            )}
            {i !== rules.length - 1 && (
              <AssembleTypography
                variant="productEyebrowSmall"
                textColor={GRAY_3}
                className={classes.orText}
              >
                OR
              </AssembleTypography>
            )}
          </div>
        );
      })}
    </div>
  );
}

function isEmpty(rules: Rule[]): boolean {
  return rules.some((rule) => rule.length === 0);
}

MultiRuleEditor.fragments = {
  organization: gql`
    fragment MultiRuleEditor_organization on Organization {
      id
      compStructure {
        id
        levels
      }
      departments {
        id
        name
        ladders {
          id
          name
        }
      }
      locationGroups {
        id
        name
        market {
          id
          name
        }
      }
    }
  `,
  compCycle: gql`
    fragment MultiRuleEditor_compCycle on CompCycle {
      perfRatings
    }
  `,
  compCycle2: gql`
    fragment MultiRuleEditor_compCycle2 on CompCycle2 {
      perfRatings
      recommendationsPreFill
    }
  `,
  employee: gql`
    ${EmailRuleEditor.fragments.employee}
    fragment MultiRuleEditor_employee on Employee {
      ...EmailRuleEditor_employee
    }
  `,
};
