import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { money } from "@asmbl/shared/money";
import { partition, roundNumber } from "@asmbl/shared/utils";
import uniqBy from "lodash/uniqBy";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import {
  CashCompType,
  CompCycleSingleRecModal_compCycle as CompCycle,
  CompUnit,
  CurrencyCode,
  CondensedTable_matrix as Matrix,
  MatrixPerfRatingOption,
  MatrixTypeEnum,
  CompCycleSingleRecModal_participant as Participant,
  RecItemInput,
  RecItemType,
} from "src/__generated__/graphql";
import { useCurrencies } from "src/components/CurrenciesContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import { CompRecommendation } from "src/models/CompRecommendation";
import {
  BonusGuidanceOption,
  BonusGuidanceResponse,
  findEligibleMatrix,
  getBonusGuidance,
  getMeritGuidance,
} from "src/models/Guidance";
import { useTableData } from "src/pages/CompCycle/Plan/Contexts/TableDataContext2";
import { getSubmittedRecommendation } from "../utils";

type MeritGuidance =
  | number
  | {
      message: string;
    };
type CompRecContextValue = {
  usingBonusGuidance: boolean;
  meritGuidance: MeritGuidance | null;
  bonusGuidance: BonusGuidanceResponse | null;
  bonusGuidanceMap: Map<MatrixTypeEnum, Matrix[]>;
  matrixPerfRatingOptions: MatrixPerfRatingOption[];
  shouldApplyMeritGuidance: boolean;
  shouldApplyBonusGuidance: boolean;
  promotedPositionId: number | null;
  revisedRecommendation: CompRecommendation | null;
  submittedRecommendation: CompRecommendation | null;
  onChangeRecommendation: (
    newRecommendation: CompRecommendation | null
  ) => void;
};

export const CompRecContext = createContext<CompRecContextValue>({
  usingBonusGuidance: false,
  meritGuidance: null,
  bonusGuidance: null,
  bonusGuidanceMap: new Map(),
  matrixPerfRatingOptions: [],
  shouldApplyMeritGuidance: false,
  shouldApplyBonusGuidance: false,
  promotedPositionId: null,
  revisedRecommendation: null,
  submittedRecommendation: null,
  onChangeRecommendation: () => {
    // noop
  },
});

export const CompRecProvider = ({
  participant,
  dataCompCycle,
  revisedPerfRating,
  loading,
  children,
}: {
  participant: Participant;
  dataCompCycle: CompCycle | null;
  revisedPerfRating: string | null;
  loading: boolean;
  children: ReactNode;
}): JSX.Element => {
  const { matrices, compCycle: tableCompCycle } = useTableData();
  const compCycle = dataCompCycle ?? tableCompCycle;
  const submittedRecommendation =
    getSubmittedRecommendation({
      ...participant,
      id: participant.subjectId,
    }) ?? null;
  const [revisedRecommendation, setRevisedRecommendation] =
    useState<CompRecommendation | null>(submittedRecommendation);
  const { defaultCurrencyCode } = useCurrencies();
  const { isEnabled } = useFeatureFlags();
  const usingBonusGuidance = isEnabled(FeatureFlag.BonusGuidance);
  const [autoAppliedBonusGuidance, setAutoAppliedBonusGuidance] =
    useState(false);

  const [promotedPositionId, setPromotedPositionId] = useState<number | null>(
    revisedRecommendation?.items.get(RecItemType.PROMOTION)
      ?.recommendedPositionId ?? null
  );

  const handleChangeRecommendation = useCallback(
    (newRecommendation: CompRecommendation | null) => {
      if (newRecommendation?.items.get(RecItemType.PROMOTION)) {
        setPromotedPositionId(
          newRecommendation.items.get(RecItemType.PROMOTION)
            ?.recommendedPositionId ?? null
        );
      }
      setRevisedRecommendation(newRecommendation);
    },
    []
  );

  const shouldApplyBonusGuidance = Boolean(
    compCycle?.bonusGuidanceRecommendationsPrefill
  );
  const shouldApplyMeritGuidance = Boolean(compCycle?.recommendationsPreFill);
  const [meritMatrices, allOtherMatrices] = partition(
    matrices,
    (matrix) => matrix.type === MatrixTypeEnum.MERIT
  );

  const meritMatrix = findEligibleMatrix(meritMatrices, participant.subjectId);

  const bonusGuidanceMap = new Map<MatrixTypeEnum, Matrix[]>();
  allOtherMatrices.forEach((matrix) => {
    if (matrix.type !== MatrixTypeEnum.BUDGET) {
      if (bonusGuidanceMap.has(matrix.type)) {
        bonusGuidanceMap.get(matrix.type)?.push(matrix);
      } else {
        bonusGuidanceMap.set(matrix.type, [matrix]);
      }
    }
  });

  const matrixGuides =
    meritMatrix?.matrixGuides.flatMap((matrixGuide) => ({
      ...matrixGuide,
      perfRatingOptionId: matrixGuide.matrixPerfRatingOption.id,
      matrixRangeId: matrixGuide.matrixRange.id,
    })) ?? [];

  const matrixPerfRatingOptions = uniqBy(
    matrixGuides.flatMap((matrixGuide) => matrixGuide.matrixPerfRatingOption),
    "name"
  );

  const matrixRanges = uniqBy(
    matrixGuides.flatMap((matrixGuide) => matrixGuide.matrixRange),
    "rangeStart"
  );

  const meritGuidance = getMeritGuidance(
    {
      ...participant.subject,
      perfRating: revisedPerfRating ?? participant.perfRating,
    },
    matrixGuides,
    matrixRanges,
    matrixPerfRatingOptions
  );

  const bonusGuidance = getBonusGuidance(
    {
      ...participant,
      bonusIndividualPerfRating: revisedPerfRating ?? participant.perfRating,
    },
    bonusGuidanceMap,
    Number(compCycle?.organizationCompCyclePerfRating)
  );

  if (usingBonusGuidance && !loading && !autoAppliedBonusGuidance) {
    const items = new Map(submittedRecommendation?.items ?? []);

    const bonusGuidanceDisabled = Object.values(bonusGuidance).every(
      (g) =>
        !g.matrix?.bonusGuidanceSettings ||
        g.matrix.bonusGuidanceSettings.isDimensionEnabled === false ||
        "message" in g
    );
    const targetCashComp = participant.subject.activeCashCompensation?.find(
      (c) => c.type === CashCompType.RECURRING_BONUS
    );

    if (!bonusGuidanceDisabled && targetCashComp) {
      const customGuidance =
        shouldApplyBonusGuidance &&
        items.get(RecItemType.ACTUAL_RECURRING_BONUS) == null
          ? bonusGuidance[MatrixTypeEnum.BONUS_GUIDANCE_CUSTOM_PERFORMANCE]
          : null;

      const indGuidance =
        shouldApplyBonusGuidance &&
        items.get(RecItemType.ACTUAL_RECURRING_BONUS) == null
          ? bonusGuidance[MatrixTypeEnum.BONUS_GUIDANCE_INDIVIDUAL_PERFORMANCE]
          : null;

      // company guidance should always be applied if it's provided
      const compGuidance = {
        ...bonusGuidance[MatrixTypeEnum.BONUS_GUIDANCE_COMPANY_PERFORMANCE],
        percent: Number(compCycle?.organizationCompCyclePerfRating) / 100,
      };

      const newBonusItems: RecItemInput[] = computeBonusGuidanceItems(
        participant,
        defaultCurrencyCode,
        shouldApplyBonusGuidance,
        indGuidance,
        compGuidance,
        customGuidance
      );
      if (newBonusItems.length > 0) {
        newBonusItems.forEach((newRecommendationItem) => {
          items.set(
            newRecommendationItem.recommendationType,
            newRecommendationItem
          );
        });
      }
    }
    if (
      shouldApplyMeritGuidance &&
      typeof meritGuidance === "number" &&
      items.get(RecItemType.MERIT_INCREASE) == null
    ) {
      items.set(RecItemType.MERIT_INCREASE, {
        recommendedPercentValue: roundNumber(Number(meritGuidance) * 100),
        recommendationType: RecItemType.MERIT_INCREASE,
        note: "Applied from guidance suggestion",
        unitType: CompUnit.PERCENT_OF_SALARY,
      });
    }
    handleChangeRecommendation({
      subjectId: participant.subjectId,
      reviewStatus: revisedRecommendation?.reviewStatus,
      items,
    });
    setAutoAppliedBonusGuidance(true);
  }

  return (
    <CompRecContext.Provider
      value={{
        usingBonusGuidance,
        meritGuidance,
        bonusGuidance,
        bonusGuidanceMap,
        matrixPerfRatingOptions,
        shouldApplyBonusGuidance,
        shouldApplyMeritGuidance,
        promotedPositionId,
        revisedRecommendation,
        submittedRecommendation,
        onChangeRecommendation: handleChangeRecommendation,
      }}
    >
      {children}
    </CompRecContext.Provider>
  );
};

export function useCompRecData() {
  return useContext(CompRecContext);
}

function computeBonusGuidanceItems(
  employee: Participant,
  defaultCurrencyCode: CurrencyCode,
  shouldApplyGuidance: boolean,
  indGuidance: BonusGuidanceOption | null,
  compGuidance: BonusGuidanceOption | null,
  customGuidance: BonusGuidanceOption | null
) {
  const newItems: RecItemInput[] = [];
  const targetCashComp = employee.subject.activeCashCompensation?.find(
    (c) => c.type === CashCompType.RECURRING_BONUS
  );
  // compGuidance, if active and not in error, should always be applied
  if (
    compGuidance &&
    "pay" in compGuidance &&
    compGuidance.matrix?.bonusGuidanceSettings?.isDimensionEnabled === true
  ) {
    newItems.push({
      recommendedCashValue: money(
        compGuidance.pay,
        targetCashComp?.annualCashEquivalent.currency ?? defaultCurrencyCode
      ),
      unitType: CompUnit.CASH,
      note: "Applied from guidance suggestion",
      recommendationType: RecItemType.ACTUAL_RECURRING_BONUS_COMPANY,
    });
  }

  if (shouldApplyGuidance) {
    if (
      indGuidance &&
      "pay" in indGuidance &&
      indGuidance.matrix?.bonusGuidanceSettings?.isDimensionEnabled === true
    ) {
      newItems.push({
        recommendedCashValue: money(
          indGuidance.pay,
          targetCashComp?.annualCashEquivalent.currency ?? defaultCurrencyCode
        ),
        unitType: CompUnit.CASH,
        note: "Applied from guidance suggestion",
        recommendationType: RecItemType.ACTUAL_RECURRING_BONUS,
      });
    }
    if (
      customGuidance &&
      "pay" in customGuidance &&
      customGuidance.matrix?.bonusGuidanceSettings?.isDimensionEnabled === true
    ) {
      newItems.push({
        recommendedCashValue: money(
          customGuidance.pay,
          targetCashComp?.annualCashEquivalent.currency ?? defaultCurrencyCode
        ),
        unitType: CompUnit.CASH,
        note: "Applied from guidance suggestion",
        recommendationType: RecItemType.ACTUAL_RECURRING_BONUS_CUSTOM,
      });
    }
  }
  return newItems;
}
