import { CurrencyCode } from "@asmbl/shared/constants";
import { Currency } from "@asmbl/shared/currency";
import { Property } from "@flatfile/api/api";

export const CurrencyField = (
  currencies: Map<CurrencyCode, Currency<CurrencyCode>>
): Property[] => [
  {
    label: "Currency",
    key: "currency",
    description: "The currency code used for this employee, e.g. USD or EUR",
    type: "enum",
    constraints: [{ type: "required" }],
    config: {
      options: [...currencies.keys()].map((code) => ({
        label: code,
        value: code,
      })),
    },
  },
];
