/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { gql } from "@apollo/client";
import { Currency } from "@asmbl/shared/currency";
import { Money } from "@asmbl/shared/money";
import { FlatfileRecord } from "@flatfile/hooks";
import { makeStyles } from "@material-ui/core";
import { useMemo } from "react";
import { AssembleFlatfileButton } from "src/components/AssembleFlatfileButton";
import {
  ActualRecurringBonusFields,
  CurrencyField,
  EmployeeIdentifierFields,
  EquityFields,
  MarketAdjustmentFields,
  MeritAdjustmentFields,
  OneTimeBonusFields,
  TargetCommissionFields,
  TargetRecurringBonusFields,
} from "src/models/csv/Fields";
import { getActualRecurringBonusValidators } from "src/models/csv/Fields/ActualRecurringBonusFields";
import { getEmployeeIdentifierValidators } from "src/models/csv/Fields/EmployeeIdentifierFields";
import { getEquityValidators } from "src/models/csv/Fields/EquityFields";
import { getMarketAdjustmentValidators } from "src/models/csv/Fields/MarketAdjustmentsFields";
import { getMeritAdjustmentValidators } from "src/models/csv/Fields/MeritAdjustmentFields";
import { getOneTimeBonusValidators } from "src/models/csv/Fields/OneTimeBonusFields";
import {
  getPromotionFields,
  getPromotionValidators,
  validatePromotion,
} from "src/models/csv/Fields/PromotionAdjustmentFields";
import { getTargetCommissionValidators } from "src/models/csv/Fields/TargetCommissionFields";
import { getTargetRecurringBonusValidators } from "src/models/csv/Fields/TargetRecurringBonusFields";
import {
  flatFileDataToValues,
  recordHasValue,
  toFFRecordLike,
} from "src/utils";
import {
  CompRecommendationInput,
  CurrencyCode,
  UploadRequestsButton_employee as Employee,
  UploadRequestsButton_position as Position,
  RecItemType,
  UploadRequestsButton_valuation as Valuation,
} from "../../../__generated__/graphql";
import {
  convertUnitsToAmount,
  matchEmployee,
  safeConvertSetPercentageSalaryToAmount,
  validatePercentageWithAmount,
} from "../../../models/csv/EmployeeCSV";
import { AssembleButton } from "../../AssembleButton/AssembleButton";
import { useCurrencies } from "../../CurrenciesContext";
import { CompCycleData } from "../CompCycleWizard/types";

export type RecommendationRequestsRow = {
  employeeId: string;
  currency: CurrencyCode;
  salaryPromotion?: string;
  salaryPromotionId?: string;
  salaryPromotionTitle?: string;
  salaryPromotionNotes?: string;
  salaryMarket?: string;
  salaryMarketNotes?: string;
  salaryMerit?: string;
  salaryMeritNotes?: string;
  targetCommission?: string;
  targetCommissionNotes?: string;
  targetRecurringBonus?: string;
  targetRecurringBonusNotes?: string;
  actualRecurringBonus?: string;
  actualRecurringBonusNotes?: string;
  oneTimeBonus?: string;
  oneTimeBonusNotes?: string;
  equity?: string;
  equityNotes?: string;
};

const useStyles = makeStyles(() => ({
  cardButton: {
    left: "50%",
    position: "absolute",
    top: "-52px",
    translate: "-50% 0",
  },
}));

type Props = {
  label: string;
  employees: Employee[];
  positions: Position[];
  currentValuation: Valuation;
  handleChange: (data: Array<CompRecommendationInput>) => Promise<unknown>;
  compComponentSettings: CompCycleData["compComponents"];
};

export function UploadRequestsButton({
  label,
  employees,
  positions,
  handleChange,
  currentValuation,
  compComponentSettings,
}: Props): JSX.Element | null {
  const { currencies, defaultCurrency } = useCurrencies();

  const employeeSalaries = useMemo(() => {
    return new Map<number, Money | null>(
      employees.map((employee) => [
        employee.id,
        employee.activeEmployment?.salary ??
          employee.activeEmployment?.payRate ??
          null,
      ])
    );
  }, [employees]);

  const validateRecord = recordValidator(
    positions,
    employees,
    employeeSalaries,
    currentValuation,
    currencies,
    defaultCurrency
  );

  const {
    allowBonus,
    allowEquity,
    allowSalary,
    allowSalaryPromotion,
    allowSalaryMarket,
    allowSalaryMerit,
    allowTargetCommission,
    allowTargetRecurringBonus,
    allowActualRecurringBonus,
  } = compComponentSettings;

  const classes = useStyles();

  return (
    <AssembleFlatfileButton
      settings={{
        name: "Add your Comp Change Requests",
        sheets: [
          {
            name: "CompRecommendation",
            slug: "CompRecommendation",
            fields: [
              ...EmployeeIdentifierFields(),
              ...CurrencyField(currencies),
              ...(allowSalary && allowSalaryPromotion
                ? getPromotionFields(positions)
                : []),
              ...(allowSalary && allowSalaryMarket
                ? MarketAdjustmentFields
                : []),
              ...(allowSalary && allowSalaryMerit ? MeritAdjustmentFields : []),
              ...(allowTargetCommission ? TargetCommissionFields : []),
              ...(allowTargetRecurringBonus ? TargetRecurringBonusFields : []),
              ...(allowActualRecurringBonus ? ActualRecurringBonusFields : []),
              ...(allowBonus ? OneTimeBonusFields : []),
              ...(allowEquity ? EquityFields : []),
            ],
          },
        ],
      }}
      validators={[
        ...getEmployeeIdentifierValidators(),
        ...(allowSalary && allowSalaryPromotion
          ? getPromotionValidators()
          : []),
        ...(allowSalary && allowSalaryMarket
          ? getMarketAdjustmentValidators()
          : []),
        ...(allowSalary && allowSalaryMerit
          ? getMeritAdjustmentValidators()
          : []),
        ...(allowTargetCommission ? getTargetCommissionValidators() : []),
        ...(allowTargetRecurringBonus
          ? getTargetRecurringBonusValidators()
          : []),
        ...(allowActualRecurringBonus
          ? getActualRecurringBonusValidators()
          : []),
        ...(allowBonus ? getOneTimeBonusValidators() : []),
        ...(allowEquity ? getEquityValidators() : []),
      ]}
      onRecordChange={validateRecord}
      onData={async ({ sheet }) => {
        if (sheet == null) {
          throw new Error("Sheet not found");
        }
        const data = await sheet.validData();
        await handleChange(
          parseData(
            flatFileDataToValues<RecommendationRequestsRow>(data),
            employees,
            employeeSalaries
          )
        );

        return;
      }}
      render={(launch) => (
        <AssembleButton
          onClick={launch}
          variant="contained"
          size="medium"
          label={label}
          className={classes.cardButton}
        />
      )}
    />
  );
}

// The specified currency should match the employee's salary or the org default
function validateCurrency(
  record: FlatfileRecord,
  expectedCurrencyForRecord: CurrencyCode
): void {
  if (
    recordHasValue(record.get("currency")) &&
    record.get("currency") !== expectedCurrencyForRecord
  ) {
    record.addError(
      "currency",
      `Currency must match Employee's salary or the Organization's default currency. Expected: ${expectedCurrencyForRecord}`
    );
  } else {
    record.set("currency", expectedCurrencyForRecord);
    record.addInfo(
      "currency",
      `This value has been prefilled based on the Employee's current salary.`
    );
  }
}

// Combine all of our validators together into a single response object.
// Beware: validators can overwrite each other, so try to keep fields separate!
function recordValidator(
  positions: Position[],
  employees: Employee[],
  employeeSalaries: Map<number, Money | null>,
  currentValuation: Valuation,
  currencies: Map<CurrencyCode, Currency>,
  defaultCurrency: Currency
) {
  return function validateRecord(record: FlatfileRecord): FlatfileRecord {
    const employee = matchEmployee(record, employees);
    const employeeSalary = employeeSalaries.get(employee?.id ?? -1) ?? null;
    const expectedCurrencyForRecord =
      employeeSalary?.currency ?? defaultCurrency.code;

    validateCurrency(record, expectedCurrencyForRecord);
    validatePromotion(record, positions);
    validatePercentageWithAmount(
      employee,
      record,
      { key: "salaryPromotion", copy: "promotion adjustment" },
      employeeSalary
    );
    validatePercentageWithAmount(
      employee,
      record,
      { key: "salaryMarket", copy: "market adjustment" },
      employeeSalary
    );
    validatePercentageWithAmount(
      employee,
      record,
      { key: "salaryMerit", copy: "merit adjustment" },
      employeeSalary
    );
    validatePercentageWithAmount(
      employee,
      record,
      { key: "targetCommission", copy: "target commission" },
      employeeSalary
    );
    validatePercentageWithAmount(
      employee,
      record,
      { key: "targetRecurringBonus", copy: "target recurring bonus" },
      employeeSalary
    );
    convertUnitsToAmount(
      record,
      currentValuation,
      currencies,
      expectedCurrencyForRecord
    );
    return record;
  };
}

type PercentageCells =
  | "salaryPromotionPercent"
  | "salaryMarketPercent"
  | "salaryMeritPercent"
  | "targetCommissionPercent"
  | "targetRecurringBonusPercent";

type ValueCells =
  | "salaryPromotion"
  | "salaryMarket"
  | "salaryMerit"
  | "targetCommission"
  | "targetRecurringBonus";

const percentageCellsToAmounts = {
  salaryPromotionPercent: "salaryPromotion",
  salaryMarketPercent: "salaryMarket",
  salaryMeritPercent: "salaryMerit",
  targetCommissionPercent: "targetCommission",
  targetRecurringBonusPercent: "targetRecurringBonus",
};

function convertPercentsToSalary(
  row: RecommendationRequestsRow,
  employee: Employee | null,
  employeeSalaries: Map<number, Money | null>
): RecommendationRequestsRow {
  const convertedPercentages = Object.keys(percentageCellsToAmounts).reduce(
    (convertedValues, percentageCellName) => {
      const valueCellName: ValueCells = percentageCellsToAmounts[
        percentageCellName as PercentageCells
      ] as ValueCells;
      if (row[valueCellName] == null || row[valueCellName] === "") {
        // will either be an empty object or the converted values
        const convertedValue = toFFRecordLike(row);
        const empSalary = employeeSalaries.get(employee?.id ?? -1) ?? null;
        if (empSalary != null) {
          safeConvertSetPercentageSalaryToAmount(
            employee,
            convertedValue,
            { key: valueCellName, copy: "" },
            empSalary
          );
        }

        return {
          ...convertedValues,
          [valueCellName]: convertedValue.get(valueCellName),
        };
      }
      return convertedValues;
    },
    {}
  );

  return {
    ...row,
    ...convertedPercentages,
  };
}

function parseData(
  validData: RecommendationRequestsRow[],
  employees: Employee[],
  employeeSalaries: Map<number, Money | null>
): CompRecommendationInput[] {
  return validData.map((row) => {
    const employee = matchEmployee(toFFRecordLike(row), employees);
    const convertedRow = convertPercentsToSalary(
      row,
      employee,
      employeeSalaries
    );

    const {
      currency,
      salaryPromotion,
      salaryPromotionId,
      salaryPromotionTitle,
      salaryPromotionNotes,
      salaryMarket,
      salaryMarketNotes,
      salaryMerit,
      salaryMeritNotes,
      targetCommission,
      targetCommissionNotes,
      targetRecurringBonus,
      targetRecurringBonusNotes,
      actualRecurringBonus,
      actualRecurringBonusNotes,
      oneTimeBonus,
      oneTimeBonusNotes,
      equity,
      equityNotes,
    } = convertedRow;
    const employeeId = employee?.id;
    if (employeeId == null) {
      throw new Error("Employee Not Found");
    }
    const salaryPromotionAmount = parseNumber(salaryPromotion);
    const salaryMarketAmount = parseNumber(salaryMarket);
    const salaryMeritAmount = parseNumber(salaryMerit);
    const targetCommissionAmount = parseNumber(targetCommission);
    const targetRecurringBonusAmount = parseNumber(targetRecurringBonus);
    const actualRecurringBonusAmount = parseNumber(actualRecurringBonus);
    const oneTimeBonusAmount = parseNumber(oneTimeBonus);
    const equityAmount = parseNumber(equity);

    return {
      subjectId: employeeId,
      items: [
        ...(salaryPromotionAmount === undefined
          ? []
          : [
              {
                recommendationType: RecItemType.PROMOTION,
                recommendedCashValue: {
                  value: salaryPromotionAmount,
                  currency,
                },
                recommendedPositionId:
                  salaryPromotionId !== undefined
                    ? parseInt(salaryPromotionId)
                    : salaryPromotionId,
                recommendedTitle: parseString(salaryPromotionTitle),
                note: parseString(salaryPromotionNotes),
              },
            ]),
        ...(salaryMarketAmount === undefined
          ? []
          : [
              {
                recommendationType: RecItemType.MARKET,
                recommendedCashValue: { value: salaryMarketAmount, currency },
                note: parseString(salaryMarketNotes),
              },
            ]),
        ...(salaryMeritAmount === undefined
          ? []
          : [
              {
                recommendationType: RecItemType.MERIT_INCREASE,
                recommendedCashValue: { value: salaryMeritAmount, currency },
                note: parseString(salaryMeritNotes),
              },
            ]),
        ...(targetCommissionAmount === undefined
          ? []
          : [
              {
                recommendationType: RecItemType.TARGET_COMMISSION,
                recommendedCashValue: {
                  value: targetCommissionAmount,
                  currency,
                },
                note: parseString(targetCommissionNotes),
              },
            ]),
        ...(targetRecurringBonusAmount === undefined
          ? []
          : [
              {
                recommendationType: RecItemType.TARGET_RECURRING_BONUS,
                recommendedCashValue: {
                  value: targetRecurringBonusAmount,
                  currency,
                },
                note: parseString(targetRecurringBonusNotes),
              },
            ]),
        ...(actualRecurringBonusAmount === undefined
          ? []
          : [
              {
                recommendationType: RecItemType.ACTUAL_RECURRING_BONUS,
                recommendedCashValue: {
                  value: actualRecurringBonusAmount,
                  currency,
                },
                note: parseString(actualRecurringBonusNotes),
              },
            ]),
        ...(oneTimeBonusAmount === undefined
          ? []
          : [
              {
                recommendationType: RecItemType.MERIT_BONUS,
                recommendedCashValue: { value: oneTimeBonusAmount, currency },
                note: parseString(oneTimeBonusNotes),
              },
            ]),
        ...(equityAmount === undefined
          ? []
          : [
              {
                recommendationType: RecItemType.EQUITY_GRANT,
                recommendedCashValue: { value: equityAmount, currency },
                note: parseString(equityNotes),
              },
            ]),
      ],
    };
  });
}

function parseNumber(num: string | undefined): number | undefined {
  return num !== undefined && num !== "" ? Number(num) : undefined;
}

function parseString(string: string | undefined): string | undefined {
  return string !== undefined && string !== "" ? string : undefined;
}

UploadRequestsButton.fragments = {
  employee: gql`
    fragment UploadRequestsButton_employee on Employee2 {
      id
      employeeNumber
      email
      personalEmail
      activeEmployment {
        id
        salary
        payRate
      }
    }
  `,
  position: gql`
    fragment UploadRequestsButton_position on Position {
      id
      name
    }
  `,
  valuation: gql`
    fragment UploadRequestsButton_valuation on Valuation {
      valuation
      fdso
    }
  `,
};
