import { CurrencyCode } from "@asmbl/shared/constants";
import { CreateWorkbookConfig } from "@flatfile/api/api";
import { DATE_REGEX } from "src/constants";
import { EmployeeIdentifierFields } from "src/models/csv/Fields";
import { getEmployeeIdentifierValidators } from "src/models/csv/Fields/EmployeeIdentifierFields";
import { FFValidator } from "src/utils";

export const EQUITY_FLATFILE_SETTINGS: CreateWorkbookConfig = {
  name: "Import your employees' equity",
  sheets: [
    {
      name: "Import your employees' equity",
      slug: "EmployeeEquityRow",
      fields: [
        ...EmployeeIdentifierFields({ allowDuplicates: true }),
        {
          label: "Grant Name",
          key: "grantName",
          constraints: [{ type: "required" }, { type: "unique" }],
          type: "string",
        },
        {
          label: "Award Type",
          key: "awardType",
          type: "string",
        },
        {
          label: "Unit Count",
          key: "unitCount",
          type: "number",
          constraints: [{ type: "required" }],
        },
        {
          label: "Price",
          key: "price",
          type: "number",
          constraints: [{ type: "required" }],
        },
        {
          label: "Price Currency Code",
          key: "priceCurrencyCode",
          type: "enum",
          constraints: [{ type: "required" }],
          config: {
            options: Object.values(CurrencyCode).map((currencyCode) => ({
              value: currencyCode,
              label: currencyCode,
            })),
          },
        },
        {
          label: "Issue Date",
          key: "issueDate",
          constraints: [{ type: "required" }],
          type: "date",
        },
        {
          label: "Vesting Start Date",
          key: "vestingStartDate",
          type: "date",
          constraints: [{ type: "required" }],
        },

        {
          label: "Vesting Schedule",
          key: "vestingScheduleDescription",
          type: "string",
          constraints: [{ type: "required" }],
        },
      ],
    },
  ],
};

export const getEquityFlatfileValidators = () => [
  ...getEmployeeIdentifierValidators(),
  FFValidator.regexMatches(
    "issueDate",
    RegExp(DATE_REGEX.source),
    "Must be in format YYYY-MM-DD or MM/DD/YYYY"
  ),
  FFValidator.regexMatches(
    "vestingStartDate",
    RegExp(DATE_REGEX.source),
    "Must be in format YYYY-MM-DD or MM/DD/YYYY"
  ),
];
export const EQUITY_VESTING_FLATFILE_SETTINGS: CreateWorkbookConfig = {
  name: "Import your employees' equity grant vesting events",
  sheets: [
    {
      name: "Import your employees' equity grant vesting events",
      slug: "EmployeeEquityVestingEventRow",
      fields: [
        ...EmployeeIdentifierFields({ allowDuplicates: true }),
        {
          label: "Grant Name",
          key: "grantName",
          constraints: [{ type: "required" }],
          type: "string",
        },
        {
          label: "Unit Count",
          key: "unitCount",
          constraints: [{ type: "required" }],
          type: "number",
        },
        {
          label: "Vesting Date",
          key: "vestingDate",
          type: "date",
          constraints: [{ type: "required" }],
        },
      ],
    },
  ],
};
export const getEquityVestingFlatfileValidators = () => [
  ...getEmployeeIdentifierValidators(),
  FFValidator.regexMatches(
    "vestingDate",
    RegExp(DATE_REGEX.source),
    "Must be in format YYYY-MM-DD or MM/DD/YYYY"
  ),
];
