/* eslint-disable no-console */
import { FetchResult, gql, useLazyQuery } from "@apollo/client";
import { Rule, RuleSet } from "@asmbl/shared/eligibility";

import { Money } from "@asmbl/shared/money";
import { roundNumber } from "@asmbl/shared/utils";
import { cloneDeep, isEqual, uniqBy } from "lodash";
import { useSnackbar } from "notistack";
import { createContext, useContext, useEffect, useState } from "react";
import {
  BuildEmptyMatrix,
  DeleteMatrix,
  groupMeritMatrices_matrix,
  HydrateMatricesQuery,
  HydrateMatricesQueryVariables,
  MatrixCard_matrixPerfRatingOption,
  MatrixCard_matrixRange,
  MatrixCard_matrixGuide as MatrixGuide,
  MatrixTypeEnum,
  UpdateMatrixEligibilityRules,
  UpdateMatrixEligibilityRulesInput,
  UpdateMatrixName,
  UpdateMatrixNameInput,
} from "src/__generated__/graphql";
import { useUpdateCompCycleGuidancePreferences } from "src/mutations/CompCycle";
import {
  useBuildEmptyMatrix,
  useCalculatePotentialParticipants,
  useSaveMatrix,
  useUpdateMatrixEligibilityRules,
  useUpdateMatrixName,
} from "src/mutations/Matrix";
import { noop } from "src/test-helpers";
import { BonusGuidanceSettings } from "../GuidanceAndBudget/BonusGuidanceAndBudget/useBonusGuidanceAndBudget";
import { groupMeritMatrices, matrixToUpdateArgs, syncMatrices } from "./utils";

export type UseMatrixMatrix = {
  id?: number;
  matrixId?: number;
  matrixGuides?: (MatrixGuide | GuideDraft)[];
  type?: MatrixTypeEnum;
  estimatedCost?: Money;
  bonusGuidanceSettings?: BonusGuidanceSettings;
};

export type MeritGuidancePopulation = {
  name: string;
  selected: boolean;
  edited: boolean;
  eligibilityRules: RuleSet | null;
  eligibleParticipants: number[] | null;
  eligibilityValidations: {
    duplicates: number[];
  };
  guidance: UseMatrixMatrix;
  budget: UseMatrixMatrix;
};

export type GuidanceEligibilityPool = {
  subjectId: number;
  subject: {
    id: number;
    displayName: string;
    activeEmployment: {
      id: number;
      jobTitle: string | null;
    } | null;
  };
};

export type RangeDraft = {
  id: string;
  rangeStart: number;
};

export type PerfRatingOptionDraft = {
  id: string;
  name: string;
  rank: number;
};

export type GuideDraft = {
  id?: string;
  percent: number;
  matrixId: number;
  matrixPerfRatingOptionId: number | string;
  matrixRangeId: number | string;
  matrixRange: RangeDraft | MatrixCard_matrixRange;
  matrixPerfRatingOption:
    | PerfRatingOptionDraft
    | MatrixCard_matrixPerfRatingOption;
};

export const MultipleMeritMatricesContext = createContext<{
  meta: {
    loading: boolean;
    pageEdited: boolean;
    setPageEdited: (edited: boolean) => void;
  };
  allEligible: GuidanceEligibilityPool[];
  matrices: {
    selectedMatrix: MeritGuidancePopulation | null;
    createMatrix: () => void;
    setSelectedMatrix: (index: number) => void;
    meritMatrices: MeritGuidancePopulation[];
    updateName: (name: string) => void;
  };
  rules: {
    addRule: () => void;
    addEmailRule: () => void;
    updateRule: (index: number) => (rule: Rule) => void;
    removeRule: (index: number) => void;
  };
  shouldPrefillWithGuidance: boolean;
  persistChanges: (
    createEmptyMatrices: () => Promise<FetchResult<BuildEmptyMatrix>>,
    updateMatrixName: (
      data: UpdateMatrixNameInput
    ) => Promise<FetchResult<UpdateMatrixName>>,
    updateMatrixEligibilityRules: (
      data: UpdateMatrixEligibilityRulesInput
    ) => Promise<FetchResult<UpdateMatrixEligibilityRules>>
  ) => Promise<void>;
  persistDelete: (
    deleteMatrix: (matrixId: number) => Promise<FetchResult<DeleteMatrix>>
  ) => Promise<void>;
  onUpdateMatrixGuide: (data: {
    newValue: string;
    currentValue: string | number;
    matrixGuideId?: number | null | string;
    matrixId: number;
  }) => unknown;
  onDeletePerfRatingOption: (perfRatingOptionId: number | string) => unknown;
  onUpdatePerfRatingOption: (
    perfRatingOptionId: number | string,
    newValue: string
  ) => unknown;
  onAddPerfRatingOption: () => unknown;
  onAddGuidanceRange: () => unknown;
  onDeleteGuidanceRange: (rangeId: number | string) => unknown;
  onUpdateGuidanceRange: (
    rangeId: number | string,
    newGuide: number
  ) => unknown;
  onToggleShouldPrefillWithGuidance: (shouldApply: boolean) => Promise<void>;
}>({
  meta: {
    loading: false,
    pageEdited: false,
    setPageEdited: noop,
  },
  allEligible: [],
  shouldPrefillWithGuidance: false,
  matrices: {
    selectedMatrix: null,
    createMatrix: noop,
    setSelectedMatrix: noop,
    meritMatrices: [],
    updateName: noop,
  },
  rules: {
    addRule: noop,
    addEmailRule: noop,
    updateRule: () => noop,
    removeRule: noop,
  },
  persistChanges: () => Promise.resolve(),
  persistDelete: () => Promise.resolve(),
  onUpdateMatrixGuide: noop,
  onDeletePerfRatingOption: noop,
  onUpdatePerfRatingOption: noop,
  onAddPerfRatingOption: noop,
  onAddGuidanceRange: noop,
  onDeleteGuidanceRange: noop,
  onUpdateGuidanceRange: noop,
  onToggleShouldPrefillWithGuidance: () => Promise.resolve(),
});

export function useMultipleMeritMatrices() {
  return useContext(MultipleMeritMatricesContext);
}

export function MultipleMeritMatricesProvider({
  compCycleId,
  children,
  initValue,
}: {
  compCycleId: number;
  children: React.ReactNode;
  initValue?: {
    allEligible?: GuidanceEligibilityPool[];
    meritMatrices?: MeritGuidancePopulation[];
    totalEligible?: number;
    shouldPrefillWithGuidance: boolean;
  };
}): JSX.Element {
  const [rehydrate] = useLazyQuery<
    HydrateMatricesQuery,
    HydrateMatricesQueryVariables
  >(MultipleMeritMatricesProvider.query, {
    variables: {
      compCycleId,
      types: [MatrixTypeEnum.MERIT, MatrixTypeEnum.BUDGET],
    },
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (loading) {
      document.body.style.cursor = "wait";
    } else {
      document.body.style.cursor = "default";
    }

    return () => {
      document.body.style.cursor = "default";
    };
  }, [loading]);
  const [pageEdited, setPageEdited] = useState(false);
  const { execute: calculateTotalEligible } =
    useCalculatePotentialParticipants();

  const [meritMatrices, setMeritMatrices] = useState<MeritGuidancePopulation[]>(
    initValue?.meritMatrices ?? [
      {
        name: "Guidance Population 1",
        selected: true,
        edited: false,
        eligibilityRules: [[]],
        eligibleParticipants: null,
        eligibilityValidations: {
          duplicates: [],
        },
        budget: {},
        guidance: {},
      },
    ]
  );

  const [allEligible] = useState<GuidanceEligibilityPool[]>(
    initValue?.allEligible ?? []
  );

  const [shouldPrefillWithGuidance, setShouldPrefillWithGuidance] =
    useState<boolean>(initValue?.shouldPrefillWithGuidance ?? false);

  const selectedMatrix =
    meritMatrices.find((matrix) => matrix.selected) ?? null;

  const updateCompCycleGuidancePreferences =
    useUpdateCompCycleGuidancePreferences(compCycleId);
  const buildEmptyMatrix = useBuildEmptyMatrix(compCycleId);
  const updateMatrixNameHook = useUpdateMatrixName();
  const updateMatrixEligibilityRulesHook = useUpdateMatrixEligibilityRules();
  const saveMatrix = useSaveMatrix();
  const { enqueueSnackbar } = useSnackbar();

  const refresh = async () => {
    const persisted = await rehydrate();
    const currentSelectedMatrixIndex = meritMatrices.findIndex(
      (matrix) => matrix.selected
    );

    setMeritMatrices(
      groupMeritMatrices(persisted.data?.compCycle2?.matrices ?? [])
    );

    setSelectedMatrix(currentSelectedMatrixIndex);
  };

  const setSelectedMatrix = (index: number) => {
    setMeritMatrices((prev) => {
      const newMatrices = [
        ...prev.map((matrix) => ({ ...matrix, selected: false })),
      ];
      newMatrices[index] = { ...newMatrices[index], selected: true };
      return newMatrices;
    });
  };

  const createMatrix = async () => {
    if (selectedMatrix?.eligibilityRules?.length === 0) {
      enqueueSnackbar(
        "Please add eligibility rules before creating a new guidance population",
        { variant: "error" }
      );

      return;
    }
    setPageEdited(true);
    const newMatrix = await buildEmptyMatrix([
      MatrixTypeEnum.BUDGET,
      MatrixTypeEnum.MERIT,
    ]);

    const newGroupedMatrices = groupMeritMatrices([
      newMatrix.data?.buildEmptyMatrix[0] as groupMeritMatrices_matrix,
      newMatrix.data?.buildEmptyMatrix[1] as groupMeritMatrices_matrix,
    ]);

    setMeritMatrices((prev) => [...prev, newGroupedMatrices[0]]);
    setSelectedMatrix(meritMatrices.length);
  };

  const addRule = () => {
    setPageEdited(true);
    setMeritMatrices((prev) => {
      if (selectedMatrix == null) return prev;

      const newMatrix = {
        ...selectedMatrix,
        eligibilityRules: [
          ...(selectedMatrix.eligibilityRules != null
            ? selectedMatrix.eligibilityRules
            : []),
          [],
        ],
      };

      return prev.map((matrix) =>
        matrix.selected === true ? newMatrix : matrix
      );
    });
  };

  const addEmailRule = () => {
    setPageEdited(true);
    setMeritMatrices((prev) => {
      if (selectedMatrix == null) return prev;

      const newMatrix = {
        ...selectedMatrix,
        eligibilityRules: [
          ...(selectedMatrix.eligibilityRules != null
            ? selectedMatrix.eligibilityRules
            : []),
          [{ alwaysIncludeIds: [] }, { alwaysExcludeIds: [] }],
        ],
      };

      return prev.map((matrix) =>
        matrix.selected === true ? newMatrix : matrix
      );
    });
  };

  const updateRule = (index: number) => async (rule: Rule) => {
    // skip the whole update, if the rule to update is the same
    // as the current rules
    const currentRules = selectedMatrix?.eligibilityRules ?? [];
    if (currentRules.length > 0 && isEqual(currentRules[index], rule)) return;

    setPageEdited(true);
    setLoading(true);

    const updateMatrix = async (
      matrices: MeritGuidancePopulation[],
      matrix: MeritGuidancePopulation
    ): Promise<MeritGuidancePopulation> => {
      const updateRules = (m: MeritGuidancePopulation) =>
        (m.selected
          ? m.eligibilityRules?.map((r, i) => (i === index ? rule : r))
          : m.eligibilityRules) ?? null;

      const currentMatrixRules = updateRules(matrix);

      const hasRuleBeenFilled = !!(
        currentMatrixRules != null &&
        !currentMatrixRules.every((rule) =>
          rule.every((condition) =>
            Object.values(condition).every(
              (c) => c == null || (Array.isArray(c) && c.length === 0)
            )
          )
        )
      );

      const potential = hasRuleBeenFilled
        ? await calculateTotalEligible({
            compCycleId: compCycleId,
            rules: currentMatrixRules,
          })
        : undefined;

      const result: MeritGuidancePopulation = {
        ...matrix,
        eligibleParticipants:
          potential?.data?.calculatePotentialParticipants ?? null,
        eligibilityRules: currentMatrixRules,
      };

      return result;
    };

    const updatedMatrices = await Promise.all(
      meritMatrices.map(
        async (matrix) => await updateMatrix(meritMatrices, matrix)
      )
    );

    const validatedMatrices = validateMatrices(updatedMatrices);

    setLoading(false);
    setMeritMatrices(validatedMatrices);
  };

  const removeRule = async (index: number) => {
    setLoading(true);
    setPageEdited(true);
    const updateMatrix = async (matrix: MeritGuidancePopulation) => {
      const updateRules = (m: MeritGuidancePopulation) =>
        (m.selected
          ? m.eligibilityRules?.filter((_, i) => i !== index)
          : m.eligibilityRules) ?? [];

      const currentMatrixRules = updateRules(matrix);

      const updatedRules =
        (matrix.selected
          ? matrix.eligibilityRules?.filter((_, i) => i !== index)
          : matrix.eligibilityRules) ?? [];

      const potential = await calculateTotalEligible({
        compCycleId: compCycleId,
        rules: currentMatrixRules,
      });

      const result: MeritGuidancePopulation = {
        ...matrix,
        eligibleParticipants:
          potential.data?.calculatePotentialParticipants ?? null,
        eligibilityRules: updatedRules,
      };

      return result;
    };

    // we need to run through the updates twice, once to go through
    // and remove the rule, and then again to update the potential and
    // validation data for each matrix (after the rule was removed)

    const updatedMatricesToRemove = await Promise.all(
      meritMatrices.map(async (matrix) => await updateMatrix(matrix))
    );

    const validatedMatrices = validateMatrices(updatedMatricesToRemove);

    setLoading(false);

    setMeritMatrices(validatedMatrices);
  };

  const updateName = (name: string) => {
    setPageEdited(true);
    setMeritMatrices((prev) => {
      if (selectedMatrix == null) return prev;

      const newMatrix = {
        ...selectedMatrix,
        name,
      };

      return prev.map((matrix) =>
        matrix.selected === true ? newMatrix : matrix
      );
    });
  };

  const persistChanges = async (
    createEmptyMatrices: (
      types: MatrixTypeEnum[]
    ) => Promise<FetchResult<BuildEmptyMatrix>> = buildEmptyMatrix,
    updateMatrixName: (
      data: UpdateMatrixNameInput
    ) => Promise<FetchResult<UpdateMatrixName>> = updateMatrixNameHook,
    updateMatrixEligibilityRules: (
      data: UpdateMatrixEligibilityRulesInput
    ) => Promise<
      FetchResult<UpdateMatrixEligibilityRules>
    > = updateMatrixEligibilityRulesHook
  ) => {
    setLoading(true);
    try {
      await Promise.all(
        meritMatrices.map(async (matrix) => {
          const matrixIds =
            matrix.guidance.id != null && matrix.budget.id != null
              ? [matrix.guidance.id, matrix.budget.id]
              : await (async () => {
                  const { data } = await createEmptyMatrices([
                    MatrixTypeEnum.BUDGET,
                    MatrixTypeEnum.MERIT,
                  ]);

                  if (!data) {
                    throw new Error("Failed to create empty matrices");
                  }

                  return data.buildEmptyMatrix.map((m) => m.id);
                })();

          await Promise.all(
            matrixIds.map(async (matrixId) => {
              await updateMatrixName({ matrixId, name: matrix.name });

              if (matrix.eligibilityRules) {
                await updateMatrixEligibilityRules({
                  matrixId,
                  rules: matrix.eligibilityRules,
                });
              }
            })
          );

          if (matrix.edited) {
            // Saving these in sequence because they share some values and we
            // don't want to create dupes
            const result = await saveMatrix(matrixToUpdateArgs(matrix.budget));

            await saveMatrix(
              matrixToUpdateArgs(
                syncMatrices(matrix.guidance, result.data?.updateMatrix)
              )
            );
          }
        })
      );

      setPageEdited(false);

      await refresh();
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const persistDelete = async (
    deleteMatrix: (matrixId: number) => Promise<FetchResult<DeleteMatrix>>
  ) => {
    if (selectedMatrix == null) {
      throw new Error("No matrix selected");
    }

    const matrixIds = [
      selectedMatrix.guidance.id,
      selectedMatrix.budget.id,
    ].filter((id) => id != null);

    await Promise.all(
      matrixIds.map(async (matrixId) => await deleteMatrix(matrixId))
    );

    const matricesPostRemoval = meritMatrices.filter(
      (m) => m.selected === false
    );

    const updateMatrix = async (mtx: MeritGuidancePopulation) => {
      const potential = await calculateTotalEligible({
        compCycleId: compCycleId,
        rules: mtx.eligibilityRules ?? [],
      });

      const result: MeritGuidancePopulation = {
        ...mtx,
        eligibleParticipants:
          potential.data?.calculatePotentialParticipants ?? null,
        eligibilityRules: mtx.eligibilityRules,
      };

      return result;
    };

    const updatedMatrices = await Promise.all(
      matricesPostRemoval.map(async (matrix) => await updateMatrix(matrix))
    );

    const validatedMatrices = validateMatrices(updatedMatrices);

    setMeritMatrices(validatedMatrices);
    setSelectedMatrix(0);
  };

  const onUpdateMatrixGuide = ({
    newValue,
    currentValue,
    matrixGuideId,
    matrixId,
  }: {
    newValue: string;
    currentValue: string | number;
    matrixGuideId?: number | null | string;
    matrixId: number;
  }) => {
    if (
      matrixGuideId != null &&
      newValue !== currentValue &&
      selectedMatrix != null &&
      selectedMatrix.budget.matrixGuides != null &&
      selectedMatrix.guidance.matrixGuides != null
    ) {
      const updatedMatrix = cloneDeep(selectedMatrix);

      if (updatedMatrix.budget.id === matrixId) {
        const guideIndex = updatedMatrix.budget.matrixGuides?.findIndex(
          (guide) => guide.id === matrixGuideId
        ) as number;

        (updatedMatrix.budget.matrixGuides as (MatrixGuide | GuideDraft)[])[
          guideIndex
        ] = {
          ...(
            updatedMatrix.budget.matrixGuides as (MatrixGuide | GuideDraft)[]
          )[guideIndex],
          percent: roundNumber(Number(newValue) / 100),
        };
      } else if (updatedMatrix.guidance.id === matrixId) {
        const guideIndex = updatedMatrix.guidance.matrixGuides?.findIndex(
          (guide) => guide.id === matrixGuideId
        ) as number;

        (updatedMatrix.guidance.matrixGuides as (MatrixGuide | GuideDraft)[])[
          guideIndex
        ] = {
          ...(
            updatedMatrix.guidance.matrixGuides as (MatrixGuide | GuideDraft)[]
          )[guideIndex],
          percent: Number((Number(newValue) / 100).toFixed(2)),
        };
      }

      updatedMatrix.edited = true;
      setPageEdited(true);
      setMeritMatrices((prev) => {
        return prev.map((matrix) =>
          matrix.selected === true ? updatedMatrix : matrix
        );
      });
    }
  };

  const onDeletePerfRatingOption = (
    perfRatingOptionId: number | null | string
  ) => {
    if (perfRatingOptionId != null) {
      const updatedMatrix = cloneDeep(selectedMatrix);

      if (
        updatedMatrix?.guidance.matrixGuides == null ||
        updatedMatrix.budget.matrixGuides == null
      )
        return;

      updatedMatrix.guidance.matrixGuides =
        updatedMatrix.guidance.matrixGuides.filter(
          (matrixGuide) =>
            matrixGuide.matrixPerfRatingOptionId !== perfRatingOptionId
        );

      updatedMatrix.budget.matrixGuides =
        updatedMatrix.budget.matrixGuides.filter(
          (matrixGuide) =>
            matrixGuide.matrixPerfRatingOptionId !== perfRatingOptionId
        );

      updatedMatrix.edited = true;
      setPageEdited(true);
      setMeritMatrices((prev) => {
        return prev.map((matrix) =>
          matrix.selected === true ? updatedMatrix : matrix
        );
      });
    }
  };

  const onUpdatePerfRatingOption = (
    perfRatingOptionId: number | string,
    newValue: string
  ) => {
    const updatedMatrix = cloneDeep(selectedMatrix);

    if (
      updatedMatrix?.guidance.matrixGuides == null ||
      updatedMatrix.budget.matrixGuides == null
    )
      return;

    updatedMatrix.guidance.matrixGuides.forEach((matrixGuide) => {
      if (matrixGuide.matrixPerfRatingOption.id === perfRatingOptionId) {
        matrixGuide.matrixPerfRatingOption.name = newValue;
      }
    });

    updatedMatrix.budget.matrixGuides.forEach((matrixGuide) => {
      if (matrixGuide.matrixPerfRatingOption.id === perfRatingOptionId) {
        matrixGuide.matrixPerfRatingOption.name = newValue;
      }
    });

    updatedMatrix.edited = true;
    setPageEdited(true);
    setMeritMatrices((prev) => {
      return prev.map((matrix) =>
        matrix.selected === true ? updatedMatrix : matrix
      );
    });
  };

  const onAddPerfRatingOption = () => {
    if (
      selectedMatrix == null ||
      selectedMatrix.budget.matrixGuides == null ||
      selectedMatrix.guidance.matrixGuides == null
    )
      return;

    const existingRanges = uniqBy(
      selectedMatrix.budget.matrixGuides.map((guide) => guide.matrixRange),
      "id"
    ).sort((a, b) => {
      const aStart = a.rangeStart ?? 0;
      const bStart = b.rangeStart ?? 0;
      return aStart < bStart ? -1 : 1;
    });
    const existingPerfRatingOptions = uniqBy(
      selectedMatrix.budget.matrixGuides.map(
        (guide) => guide.matrixPerfRatingOption
      ),
      "id"
    );

    const newOptionId = `new-${existingPerfRatingOptions.length}`;

    const newBudgetGuides: GuideDraft[] = existingRanges.map((range, idx) => {
      return {
        id: `new-${(selectedMatrix.budget.matrixGuides as MatrixGuide[]).length + idx}`,
        percent: 0,
        matrixId: selectedMatrix.budget.id as number,
        matrixPerfRatingOptionId: newOptionId,
        matrixRangeId: range.id,
        matrixRange: range,
        matrixPerfRatingOption: {
          id: newOptionId,
          name: "",
          rank: existingPerfRatingOptions.length + 1,
        },
      };
    });
    const newMeritGuides: GuideDraft[] = existingRanges.map((range, idx) => {
      return {
        id: `new-${(selectedMatrix.budget.matrixGuides as MatrixGuide[]).length + idx}`,
        percent: 0,
        matrixId: selectedMatrix.budget.id as number,
        matrixPerfRatingOptionId: newOptionId,
        matrixRangeId: range.id,
        matrixRange: range,
        matrixPerfRatingOption: {
          id: newOptionId,
          name: "",
          rank: existingPerfRatingOptions.length + 1,
        },
      };
    });

    const updatedMatrix = cloneDeep(selectedMatrix);

    if (
      updatedMatrix.budget.matrixGuides == null ||
      updatedMatrix.guidance.matrixGuides == null
    )
      return;

    updatedMatrix.budget.matrixGuides =
      updatedMatrix.budget.matrixGuides.concat(newBudgetGuides);
    updatedMatrix.guidance.matrixGuides =
      updatedMatrix.guidance.matrixGuides.concat(newMeritGuides);

    updatedMatrix.edited = true;
    setPageEdited(true);
    setMeritMatrices((prev) => {
      return prev.map((matrix) =>
        matrix.selected === true ? updatedMatrix : matrix
      );
    });
  };

  const onAddGuidanceRange = () => {
    if (
      selectedMatrix == null ||
      selectedMatrix.budget.matrixGuides == null ||
      selectedMatrix.guidance.matrixGuides == null
    )
      return;

    const existingRanges = uniqBy(
      selectedMatrix.budget.matrixGuides.map((guide) => guide.matrixRange),
      "id"
    ).sort((a, b) => {
      const aStart = a.rangeStart ?? 0;
      const bStart = b.rangeStart ?? 0;
      return aStart < bStart ? -1 : 1;
    });

    const existingPerfRatingOptions = uniqBy(
      selectedMatrix.budget.matrixGuides.map(
        (guide) => guide.matrixPerfRatingOption
      ),
      "id"
    );

    const newRangeId = `new-${existingRanges.length}`;

    const newBudgetGuides: GuideDraft[] = existingPerfRatingOptions.map(
      (perfRatingOption, idx) => {
        return {
          id: `new-${(selectedMatrix.budget.matrixGuides as MatrixGuide[]).length + idx}`,
          percent: 0,
          matrixId: selectedMatrix.budget.id as number,
          matrixPerfRatingOptionId: perfRatingOption.id,
          matrixRangeId: newRangeId,
          matrixRange: {
            id: newRangeId,
            rangeStart:
              existingRanges[existingRanges.length - 1].rangeStart ?? 0 + 0.1,
          },
          matrixPerfRatingOption: perfRatingOption,
        };
      }
    );
    const newMeritGuides: GuideDraft[] = existingPerfRatingOptions.map(
      (perfRatingOption, idx) => {
        return {
          id: `new-${(selectedMatrix.budget.matrixGuides as MatrixGuide[]).length + idx}`,
          percent: 0,
          matrixId: selectedMatrix.guidance.id as number,
          matrixPerfRatingOptionId: perfRatingOption.id,
          matrixRangeId: newRangeId,
          matrixRange: {
            id: newRangeId,
            rangeStart:
              existingRanges[existingRanges.length - 1].rangeStart ?? 0 + 0.1,
          },
          matrixPerfRatingOption: perfRatingOption,
        };
      }
    );

    const updatedMatrix = cloneDeep(selectedMatrix);

    if (
      updatedMatrix.budget.matrixGuides == null ||
      updatedMatrix.guidance.matrixGuides == null
    )
      return;

    updatedMatrix.budget.matrixGuides =
      updatedMatrix.budget.matrixGuides.concat(newBudgetGuides);
    updatedMatrix.guidance.matrixGuides =
      updatedMatrix.guidance.matrixGuides.concat(newMeritGuides);

    updatedMatrix.edited = true;

    setPageEdited(true);
    setMeritMatrices((prev) => {
      return prev.map((matrix) =>
        matrix.selected === true ? updatedMatrix : matrix
      );
    });
  };

  const onDeleteGuidanceRange = (rangeId: number | string) => {
    const updatedMatrix = cloneDeep(selectedMatrix);

    if (
      updatedMatrix?.guidance.matrixGuides == null ||
      updatedMatrix.budget.matrixGuides == null
    )
      return;

    updatedMatrix.guidance.matrixGuides =
      updatedMatrix.guidance.matrixGuides.filter(
        (matrixGuide) => matrixGuide.matrixRangeId !== rangeId
      );

    updatedMatrix.budget.matrixGuides =
      updatedMatrix.budget.matrixGuides.filter(
        (matrixGuide) => matrixGuide.matrixRangeId !== rangeId
      );

    updatedMatrix.edited = true;
    setPageEdited(true);
    setMeritMatrices((prev) => {
      return prev.map((matrix) =>
        matrix.selected === true ? updatedMatrix : matrix
      );
    });
  };

  const onUpdateGuidanceRange = (
    rangeId: number | string,
    newRangeStart: number
  ) => {
    const updatedMatrix = cloneDeep(selectedMatrix);

    if (
      updatedMatrix?.guidance.matrixGuides == null ||
      updatedMatrix.budget.matrixGuides == null
    )
      return;

    updatedMatrix.guidance.matrixGuides.forEach((matrixGuide) => {
      if (matrixGuide.matrixRange.id === rangeId) {
        matrixGuide.matrixRange.rangeStart = Number(newRangeStart.toFixed(2));
      }
    });

    updatedMatrix.budget.matrixGuides.forEach((matrixGuide) => {
      if (matrixGuide.matrixRange.id === rangeId) {
        matrixGuide.matrixRange.rangeStart = Number(newRangeStart.toFixed(2));
      }
    });

    updatedMatrix.edited = true;
    setPageEdited(true);
    setMeritMatrices((prev) => {
      return prev.map((matrix) =>
        matrix.selected === true ? updatedMatrix : matrix
      );
    });
  };

  const onToggleShouldPrefillWithGuidance = async (shouldApply: boolean) => {
    setLoading(true);
    try {
      await updateCompCycleGuidancePreferences(shouldApply);
      setShouldPrefillWithGuidance(shouldApply);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MultipleMeritMatricesContext.Provider
      value={{
        meta: {
          loading,
          pageEdited,
          setPageEdited,
        },
        allEligible,
        matrices: {
          selectedMatrix,
          createMatrix,
          setSelectedMatrix,
          meritMatrices,
          updateName,
        },
        shouldPrefillWithGuidance,
        rules: {
          addRule,
          addEmailRule,
          updateRule,
          removeRule,
        },
        persistChanges,
        persistDelete,
        onUpdateMatrixGuide,
        onDeletePerfRatingOption,
        onUpdatePerfRatingOption,
        onAddPerfRatingOption,
        onAddGuidanceRange,
        onDeleteGuidanceRange,
        onUpdateGuidanceRange,
        onToggleShouldPrefillWithGuidance,
      }}
    >
      {children}
    </MultipleMeritMatricesContext.Provider>
  );
}

MultipleMeritMatricesProvider.query = gql`
  ${groupMeritMatrices.fragments.matrix}
  query HydrateMatricesQuery($compCycleId: Int!, $types: [MatrixTypeEnum!]!) {
    compCycle2(id: $compCycleId) {
      id
      matrices(types: $types) {
        id
        ...groupMeritMatrices_matrix
      }
    }
  }
`;

MultipleMeritMatricesProvider.fragments = {
  compCycle: gql`
    fragment useMultipleMatrices_compCycle on CompCycle2 {
      recommendationsPreFill
      participants {
        participants {
          subjectId
          subject {
            id
            displayName
            activeEmployment {
              id
              jobTitle
            }
          }
        }
      }
    }
  `,
};

function validateMatrices(matrices: MeritGuidancePopulation[]) {
  return matrices.map((matrix) => {
    const otherParticipants = matrices
      .filter((m) => m !== matrix)
      .map((m) => m.eligibleParticipants ?? []);

    const result: MeritGuidancePopulation = {
      ...matrix,
      eligibilityValidations: {
        duplicates:
          matrix.eligibleParticipants?.filter((id) => {
            return otherParticipants.some((participants) =>
              participants.includes(id)
            );
          }) ?? [],
      },
    };

    return result;
  });
}
