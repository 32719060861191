import { gql } from "@apollo/client";
import { AvailablePrimaryRoleNames } from "@asmbl/shared/permissions";
import { mapify } from "@asmbl/shared/utils";

import { CreateWorkbookConfig } from "@flatfile/api/api";
import { FlatfileRecord } from "@flatfile/hooks";
import * as changeCase from "change-case";
import { FFValidator, flatFileDataToValues } from "src/utils";
import {
  UploadUsersWithGrantsButton_organization as Organization,
  PrimaryRoleName,
  UpsertUsersWithAccessGrantsVariables,
} from "../__generated__/graphql";
import { useUpsertUsersWithAccessGrants } from "../mutations/User";
import { AssembleButton } from "./AssembleButton/AssembleButton";
import { AssembleFlatfileButton } from "./AssembleFlatfileButton";

type Props = {
  organization: Organization;
};

type ExpectedData = {
  name: string;
  email: string;
  roleName: PrimaryRoleName;
  canViewCompBands: boolean;
  canViewGlobal: boolean;
  departmentIDs: string;
  ladderIDs: string;
  positionIDs: string;
};

const NUMBERED_LIST_REGEX = /^[0-9]*([,\s]*[0-9])*$/.source;

const SETTINGS: CreateWorkbookConfig = {
  name: "Add users and access grants",
  sheets: [
    {
      name: "Add users and access grants",
      slug: "UploadUsersWithGrants",

      fields: [
        {
          label: "Name",
          key: "name",
          constraints: [{ type: "required" }],
          type: "string",
        },
        {
          label: "Email",
          key: "email",
          constraints: [{ type: "required" }, { type: "unique" }],
          type: "string",
        },
        {
          label: "Role Name",
          key: "roleName",
          type: "enum",
          description:
            "The user's primary role. This will replace their current role. ",
          config: {
            options: AvailablePrimaryRoleNames.map((name) => ({
              label: changeCase.capitalCase(name),
              value: name,
            })),
          },
          constraints: [{ type: "required" }],
        },
        {
          label: "Can View Comp Bands",
          key: "canViewCompBands",
          type: "boolean",
          description: "Enable this to allow the user to see comp bands.",
          constraints: [{ type: "required" }],
        },
        {
          label: "Global Band Access",
          key: "canViewGlobal",
          type: "boolean",
          description:
            "Enable this to allow the user to see ALL comp bands in the company.",
        },
        {
          label: "Department IDs",
          key: "departmentIDs",
          description:
            "A list of department IDs that the user can see comp bands for, separated by commas.",
          type: "string",
        },
        {
          label: "Ladder IDs",
          key: "ladderIDs",
          description:
            "A list of ladder IDs that the user can see comp bands for, separated by commas.",
          type: "string",
        },
        {
          label: "Position IDs",
          key: "positionIDs",
          description:
            "A list of position IDs that the user can see comp bands for, separated by commas.",
          type: "string",
        },
      ],
    },
  ],
};

export const getUploadUsersWithGrantsValidators = () => [
  FFValidator.requiredWith("canViewGlobal", ["canViewCompBands"]),
  FFValidator.regexMatches(
    "departmentIDs",
    RegExp(NUMBERED_LIST_REGEX),
    "Must be a comma-separated list of IDs"
  ),
  FFValidator.requiredWithAllValues("departmentIDs", {
    canViewCompBands: true,
    canViewGlobal: false,
    departmentIDs: "",
    ladderIDs: "",
    positionIDs: "",
  }),
  FFValidator.regexMatches(
    "ladderIDs",
    RegExp(NUMBERED_LIST_REGEX),
    "Must be a comma-separated list of IDs"
  ),
  FFValidator.requiredWithAllValues("ladderIDs", {
    canViewCompBands: true,
    canViewGlobal: false,
    departmentIDs: "",
    ladderIDs: "",
    positionIDs: "",
  }),
  FFValidator.regexMatches(
    "positionIDs",
    RegExp(NUMBERED_LIST_REGEX),
    "Must be a comma-separated list of IDs"
  ),
  FFValidator.requiredWithAllValues("positionIDs", {
    canViewCompBands: true,
    canViewGlobal: false,
    departmentIDs: "",
    ladderIDs: "",
    positionIDs: "",
  }),
];

export function UploadUsersWithGrantsButton({
  organization,
}: Props): JSX.Element {
  const upsertUsers = useUpsertUsersWithAccessGrants();
  const validator = getValidator(organization);

  return (
    <AssembleFlatfileButton
      settings={SETTINGS}
      validators={getUploadUsersWithGrantsValidators()}
      onRecordChange={validator}
      onData={async ({ sheet }) => {
        if (sheet == null) {
          throw new Error("Sheet not found");
        }
        const data = await sheet.validData();
        await upsertUsers(parseData(flatFileDataToValues(data)));

        document.body.classList.remove("flatfile-active");
        return;
      }}
      render={(launch) => (
        <AssembleButton
          onClick={launch}
          variant="contained"
          label="Bulk add users and access grants"
          size="medium"
        />
      )}
    />
  );
}

function getValidator(
  organization: Organization
): (record: FlatfileRecord) => void {
  const existingUsersMap = mapify(organization.users, "email", "name");

  return (record) => {
    const name = record.get("name") as string;
    const email = record.get("email") as string;
    const roleName = record.get("roleName");
    const canViewCompBands = record.get("canViewCompBands") as boolean;
    const canViewGlobal = record.get("canViewGlobal") as boolean;
    const departmentIDs = record.get("departmentIDs") as string;
    const ladderIDs = record.get("ladderIDs") as string;
    const positionIDs = record.get("positionIDs") as string;

    const invalidDepartmentIds = findMissingIds(
      organization.departments.map((d) => d.id),
      listToArray(departmentIDs)
    );
    const invalidLadderIds = findMissingIds(
      organization.departments.flatMap((d) => d.ladders).map((l) => l.id),
      listToArray(ladderIDs)
    );
    const invalidPositionIds = findMissingIds(
      organization.departments
        .flatMap((d) => d.ladders)
        .flatMap((l) => l.positions)
        .map((l) => l.id),
      listToArray(positionIDs)
    );

    record.set("name", existingUsersMap.get(email) ?? name);
    if (existingUsersMap.has(name)) {
      record.addInfo(
        "name",
        "This user already has a name and it will not be updated."
      );
    }

    record.set("email", email.toLocaleLowerCase());
    if (existingUsersMap.has(email)) {
      record.addWarning("email", "This will modify an existing user.");
    }

    record.set(
      "canViewCompBands",
      roleName === PrimaryRoleName.FULL_ACCESS || canViewCompBands
    );
    if (roleName === PrimaryRoleName.FULL_ACCESS) {
      record.addInfo("canViewCompBands", "Full Access can view all comp bands");
    }

    record.set(
      "canViewGlobal",
      roleName === PrimaryRoleName.FULL_ACCESS ||
        (canViewCompBands && canViewGlobal)
    );
    if (roleName === PrimaryRoleName.FULL_ACCESS) {
      record.addInfo("canViewGlobal", "Full Access can view all comp bands");
    }

    record.set("departmentIDs", canViewGlobal ? "" : departmentIDs);
    if (invalidDepartmentIds.length > 0) {
      record.addError(
        "departmentIDs",
        `Invalid IDs: ${invalidDepartmentIds.join(", ")}`
      );
    }

    record.set("ladderIDs", canViewGlobal ? "" : ladderIDs);
    if (invalidLadderIds.length > 0) {
      record.addError(
        "ladderIDs",
        `Invalid IDs: ${invalidLadderIds.join(", ")}`
      );
    }

    record.set("positionIDs", canViewGlobal ? "" : positionIDs);
    if (invalidPositionIds.length > 0) {
      record.addError(
        "positionIDs",
        `Invalid IDs: ${invalidPositionIds.join(", ")}`
      );
    }
  };
}

function parseData(data: unknown[]): UpsertUsersWithAccessGrantsVariables {
  const castedData = data as ExpectedData[];
  const users = castedData.map((d) => ({
    email: d.email,
    name: d.name,
    roleName: d.roleName,
    compBandAccess: d.canViewCompBands
      ? {
          global: d.canViewGlobal,
          departmentIDs: listToArray(d.departmentIDs),
          ladderIDs: listToArray(d.ladderIDs),
          positionIDs: listToArray(d.positionIDs),
        }
      : null,
  }));
  return { users };
}

function listToArray(list: string): number[] {
  if (list.trim() === "") return [];
  return list.split(",").map((c) => parseInt(c));
}

function findMissingIds(existingIds: number[], newIds: number[]) {
  return newIds.reduce<number[]>(
    (acc, cur) => (existingIds.includes(cur) ? acc : acc.concat(cur)),
    []
  );
}

UploadUsersWithGrantsButton.fragments = {
  organization: gql`
    fragment UploadUsersWithGrantsButton_organization on Organization {
      id
      users {
        id
        email
        name
      }
      departments {
        id
        ladders {
          id
          positions {
            id
          }
        }
      }
    }
  `,
};
