import { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../components/Auth/AuthContext";
import { EmailVerification } from "./EmailVerification";

/**
 * Redirects to the login screen if you're not yet authenticated.
 */
export function AuthOrRedirect({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const { isLoading, user } = useAuth();

  return user ? (
    <AuthenticatedPage user={user} children={children} />
  ) : (
    <AuthenticationPage isLoading={isLoading} />
  );
}

function AuthenticationPage({
  isLoading,
}: {
  isLoading: boolean;
}): JSX.Element {
  const location = useLocation();
  return isLoading ? (
    <div></div>
  ) : (
    <Navigate
      to={{
        pathname: "/authentication",
        // We want to persist the search params in case someone clicks an
        // intercom survey from their email but is not logged in.
        search: location.search,
      }}
      state={{ from: location }}
      replace
    />
  );
}

function AuthenticatedPage({
  user,
  children,
}: {
  user: { emailVerified: boolean };
  children: ReactNode;
}): JSX.Element {
  return user.emailVerified ? <>{children}</> : <EmailVerification />;
}
