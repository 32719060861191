/* eslint-disable @typescript-eslint/no-base-to-string */
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { isEmptyString } from "@asmbl/shared/utils";

import { FlatfileRecord } from "@flatfile/hooks";
import { Button } from "@material-ui/core";
import { AssembleFlatfileButton } from "src/components/AssembleFlatfileButton";
import { useCompStructure } from "src/components/CompStructureContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import { FlatfileDataResponse } from "src/types/flatfileTypes";
import { flatFileDataToValues, recordHasValue } from "src/utils";
import {
  getHrisFlatfileValidators,
  getHrisHourlyFlatfileValidators,
  HRIS_FLATFILE_SETTTINGS,
  HRIS_HOURLY_FLATFILE_SETTINGS,
} from "./flatfileConfig";

export type RipplingImportRecord = {
  employeeNumber: string;
  workEmail: string;
  personalEmail: string | null;
  displayName: string;
  employmentStatus: string;
  managerWorkEmail: string;
  city: string;
  state: string | null;
  country: string;
  firstName: string | null;
  lastName: string | null;
  gender: string | null;
  ethnicity: string | null;
  startDate: string | null;
  terminationDate: string | null;
  payRate: string;
  payPeriod: string;
  payCurrency: string;
  employmentType: string;
  jobTitle: string;
  effectiveDate: string;
};

interface Props {
  handleChange: (data: RipplingImportRecord[]) => unknown;
  handleCancel?: () => unknown;
}

export function RipplingImportButton({
  handleChange,
  handleCancel,
}: Props): JSX.Element {
  const { isEnabled } = useFeatureFlags();
  const { compStructure } = useCompStructure();
  const enabledHourlyEmployees =
    isEnabled(FeatureFlag.HourlyEmployees) &&
    compStructure?.allowHourlyEmployees === true;
  const settings = enabledHourlyEmployees
    ? HRIS_HOURLY_FLATFILE_SETTINGS
    : HRIS_FLATFILE_SETTTINGS;
  const validators = enabledHourlyEmployees
    ? getHrisHourlyFlatfileValidators()
    : getHrisFlatfileValidators();
  const validateRecord = recordValidator();
  return (
    <AssembleFlatfileButton
      settings={settings}
      validators={validators}
      onRecordChange={validateRecord}
      onData={async ({ sheet }) => {
        if (sheet == null) {
          throw new Error("Sheet not found");
        }
        const data: FlatfileDataResponse = await sheet.validData();
        const values = flatFileDataToValues<
          RipplingImportRecord & { hourlyPayRate: string | null }
        >(data);

        const processedRecords: RipplingImportRecord[] = values.map(
          ({ payRate, hourlyPayRate, ...record }) => {
            // explicity cast to RipplingImportRecord to avoid passing hourlyPayRate to the API
            return {
              ...record,
              ...processPayType(payRate, hourlyPayRate),
            };
          }
        );
        handleChange(processedRecords);
        return Promise.resolve();
      }}
      onCancel={handleCancel}
      render={(launch) => (
        <Button onClick={launch} variant="contained" color="secondary">
          Payroll Report
        </Button>
      )}
    />
  );
}

function processPayType(
  payRate: string | null,
  hourlyPayRate: string | null
): {
  payRate: string;
  payPeriod: "HOUR" | "YEAR";
} {
  return payRate != null && !isEmptyString(payRate)
    ? {
        payRate,
        payPeriod: "YEAR",
      }
    : {
        payRate: hourlyPayRate ?? "",
        payPeriod: "HOUR",
      };
}

function recordValidator() {
  return function validateRecord(record: FlatfileRecord): FlatfileRecord {
    const payRate = record.get("payRate");
    const hourlyPayRate = record.get("hourlyPayRate");
    const noPayRate =
      !recordHasValue(payRate) && !recordHasValue(hourlyPayRate);
    // make sure only one compensation column has a value
    if (recordHasValue(payRate) && recordHasValue(hourlyPayRate)) {
      record.addError(
        ["payRate", "hourlyPayRate"],
        "Cannot provide both annual compensation and hourly compensation. Please only provide one."
      );
    } else if (noPayRate) {
      record.addError(
        ["payRate", "hourlyPayRate"],
        "Either annual compensation or hourly compensation is required."
      );
    }
    return record;
  };
}
