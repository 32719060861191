import { CurrencyCode } from "@asmbl/shared/constants";
import { Currency, exchangeFromTo } from "@asmbl/shared/currency";
import {
  map,
  money,
  Money,
  percentageOfSalaryToCash,
  totalGrossEquityValue,
} from "@asmbl/shared/money";
import { isEmptyString } from "@asmbl/shared/utils";
import { FlatfileRecord, TRecordValue } from "@flatfile/hooks";
import { toInteger, toNumber } from "lodash";
import { UploadRequestsButton_valuation as Valuation } from "../../__generated__/graphql";
import { capitalize, recordHasValue } from "../../utils";

type Employee = {
  id: number;
  employeeNumber: string | null | undefined;
  email: string;
  personalEmail: string | null | undefined;
};

type EmployeeFields = {
  id: number | null;
  employeeNumber: string | null;
  email: string | null;
};

function parseEmployeeId(employeeId: TRecordValue): number | null {
  return recordHasValue(employeeId) ? toInteger(employeeId) : null;
}

function validateActualAgainstExpected(
  actual: Employee,
  expected: EmployeeFields,
  record: FlatfileRecord
) {
  const idMismatch = expected.id != null && expected.id !== actual.id;

  const employeeNumberMismatch =
    expected.employeeNumber == null ||
    (expected.employeeNumber.length > 0 &&
      expected.employeeNumber !== actual.employeeNumber);

  if (idMismatch) {
    record.addError(
      "employeeId",
      `Found Employee has different Assemble ID: ${actual.id}`
    );
  }

  if (employeeNumberMismatch) {
    if (actual.employeeNumber == null) {
      record.addInfo(
        "employeeNumber",
        "Found Employee has no Employee Number in Assemble"
      );
    } else {
      record.addError(
        "employeeNumber",
        `Found Employee has different Employee Number: ${actual.employeeNumber}`
      );
    }
  }
}

export function matchEmployee<E extends Employee>(
  record: FlatfileRecord,
  employees: E[]
): E | null {
  const employeeId = parseEmployeeId(record.get("employeeId"));
  const employeeNumber = recordHasValue(record.get("employeeNumber"))
    ? String(record.get("employeeNumber"))
    : null;
  const email = recordHasValue(record.get("email"))
    ? String(record.get("email"))
    : null;

  const expectedEmployee: EmployeeFields = {
    id: employeeId,
    employeeNumber,
    email,
  };

  if (employeeId != null) {
    const employeeById = employees.find((e) => e.id === employeeId);

    if (employeeById) {
      validateActualAgainstExpected(employeeById, expectedEmployee, record);
      return employeeById;
    }

    // If you provide an Assemble ID, it must be correct. We don't fall through.
    record.addError(
      "employeeId",
      `We couldn't find an Employee with this ID: ${employeeId}`
    );
    return null;
  }

  if (employeeNumber != null) {
    const employeesByNumber = employees.filter(
      (e) => e.employeeNumber === employeeNumber
    );

    if (employeesByNumber.length > 1) {
      record.addError(
        "employeeNumber",
        "Multiple employees match this Employee Number. Please correct this in your HRIS or use a different identifier."
      );
      return null;
    }

    if (employeesByNumber.length === 1) {
      validateActualAgainstExpected(
        employeesByNumber[0],
        expectedEmployee,
        record
      );
      return employeesByNumber[0];
    }

    // Employee Number could be null in our system, so we keep trying.
  }

  if (email != null) {
    const employeeByWorkEmail = employees.find((e) => e.email === email);

    if (employeeByWorkEmail) {
      validateActualAgainstExpected(
        employeeByWorkEmail,
        expectedEmployee,
        record
      );
      return employeeByWorkEmail;
    }

    // Employees could have different emails across systems, so we keep trying.
  }

  record.addError(
    ["employeeId", "employeeNumber", "email"],
    "No matching Employee found"
  );
  return null;
}

export function validateDate(record: FlatfileRecord, key: string): void {
  const input = record.get(key);

  if (input == null || isEmptyString(input as string)) return;

  const date = new Date(input as string);

  if (isNaN(date.getTime())) {
    record.addError(key, "This is not a valid date.");
    return;
  }
  return;
}

export function validatePercentageWithAmount(
  employee: Employee | null,
  record: FlatfileRecord,
  data: {
    key:
      | "salaryPromotion"
      | "salaryMarket"
      | "salaryMerit"
      | "targetCommission"
      | "targetRecurringBonus";
    copy: string;
  },
  employeeSalary: Money | null
): void {
  const { key, copy } = data;
  const percentFieldKey = `${key}Percent`;

  if (employee == null || employeeSalary == null) {
    record.addWarning(
      percentFieldKey,
      "Salary is not known for this employee."
    );
    return;
  }

  const value = record.get(key);
  const percent = record.get(percentFieldKey);

  if (recordHasValue(value) && recordHasValue(percent)) {
    const actualPercentageOfSalaryToCash = percentageOfSalaryToCash(
      employeeSalary,
      typeof percent === "number" ? percent : parseFloat(percent as string)
    );

    if (
      actualPercentageOfSalaryToCash.value !==
      (typeof value === "number" ? value : parseFloat(value as string))
    ) {
      record.addWarning(
        percentFieldKey,
        `${capitalize(copy)} value does not match the ${copy} percentage.`
      );
      record.addWarning(
        percentFieldKey,
        `${capitalize(
          copy
        )} value and percentage both specified. This value will be ignored.`
      );
    }
  }

  return;
}

export function validatePercentageSalary(
  record: FlatfileRecord,
  data: {
    key:
      | "salaryPromotion"
      | "salaryMarket"
      | "salaryMerit"
      | "targetCommission"
      | "targetRecurringBonus";
    copy: string;
  },
  employeeSalary: Money
): void {
  const { key, copy } = data;
  const percentFieldKey = `${key}Percent`;

  const value = record.get(key);
  const percent = record.get(percentFieldKey);

  if (recordHasValue(value) && recordHasValue(percent)) {
    const actualPercentageOfSalaryToCash = percentageOfSalaryToCash(
      employeeSalary,
      toNumber(percent)
    );

    if (actualPercentageOfSalaryToCash.value !== toInteger(value)) {
      record.addWarning(
        percentFieldKey,
        `${capitalize(copy)} value does not match the ${copy} percentage.`
      );
      record.addWarning(
        percentFieldKey,
        `${capitalize(
          copy
        )} value and percentage both specified. This value will be ignored.`
      );
    } else {
      record.addWarning(
        percentFieldKey,
        `${capitalize(
          copy
        )} value and percentage both specified. This value will be ignored.`
      );
    }
  }
}

export function convertSetPercentageSalaryToAmount(
  record: FlatfileRecord,
  data: {
    key:
      | "salaryPromotion"
      | "salaryMarket"
      | "salaryMerit"
      | "targetCommission"
      | "targetRecurringBonus";
    copy: string;
  },
  employeeSalary: Money
): void {
  const { key } = data;
  const percentFieldKey = `${key}Percent`;

  const percent = record.get(percentFieldKey);

  const actualPercentageOfSalaryToCash = percentageOfSalaryToCash(
    employeeSalary,
    toNumber(percent)
  );
  record.set(key, actualPercentageOfSalaryToCash.value);
  record.addInfo(key, `Calculated from the current salary.`);
}

export function safeConvertSetPercentageSalaryToAmount(
  employee: Employee | null,
  record: FlatfileRecord,
  data: {
    key:
      | "salaryPromotion"
      | "salaryMarket"
      | "salaryMerit"
      | "targetCommission"
      | "targetRecurringBonus";
    copy: string;
  },
  employeeSalary: Money
): void {
  const { key } = data;
  const percentFieldKey = `${key}Percent`;

  const value = record.get(key);
  const percent = record.get(percentFieldKey);

  if (recordHasValue(percent) && !recordHasValue(value)) {
    convertSetPercentageSalaryToAmount(record, data, employeeSalary);
  }
}

export function validateAndConvertPercentageSalaryToAmount(
  employee: Employee | null,
  record: FlatfileRecord,
  data: {
    key:
      | "salaryPromotion"
      | "salaryMarket"
      | "salaryMerit"
      | "targetCommission"
      | "targetRecurringBonus";
    copy: string;
  },
  employeeSalary: Money | null
): void {
  const { key } = data;
  const percentFieldKey = `${key}Percent`;

  if (employee === null || employeeSalary === null) {
    record.addWarning(
      percentFieldKey,
      "Salary is not known for this employee."
    );
    return;
  }
  validatePercentageSalary(record, data, employeeSalary);
  safeConvertSetPercentageSalaryToAmount(
    employee,
    record,
    data,
    employeeSalary
  );
}

// We can only submit equity in absolute amounts; convert units as a convenience
export function convertUnitsToAmount(
  record: FlatfileRecord,
  currentValuation: Valuation,
  currencies: Map<CurrencyCode, Currency>,
  targetCurrencyCode: CurrencyCode
): void {
  const valuationCurrency = currencies.get(currentValuation.valuation.currency);
  if (!valuationCurrency) {
    throw new Error(
      `Exchange rate not found for valuation currency: ${currentValuation.valuation.currency}`
    );
  }
  const targetCurrency = currencies.get(targetCurrencyCode);
  if (!targetCurrency) {
    throw new Error(
      `Exchange rate not found for target currency: ${targetCurrencyCode}`
    );
  }

  const equityUnits = record.get("equityUnits");

  if (!recordHasValue(equityUnits)) {
    return;
  }

  const units = toInteger(equityUnits);

  const unitValue = map(
    Math.round,
    exchangeFromTo(
      totalGrossEquityValue(
        currentValuation.fdso,
        currentValuation.valuation,
        units
      ),
      targetCurrency,
      valuationCurrency
    )
  );

  const equity = record.get("equity");

  if (recordHasValue(equity)) {
    const equityValue = money(toInteger(equity), targetCurrency.code);

    if (unitValue.value !== equityValue.value) {
      record.addWarning(
        "equityUnits",
        "Equity Units and Amount both specified. This value will be ignored."
      );
    }
  } else {
    // Equity Amount was not specified, so populate it with unit equivalent
    record.set("equity", unitValue.value);
    record.addInfo("equity", "Calculated from current valuation");
  }
}
