import { Flatfile } from "@flatfile/api";
import { FlatfileRecord } from "@flatfile/hooks";
import { recordHook } from "@flatfile/plugin-record-hook";
import {
  FlatfileProvider,
  Sheet,
  Space,
  useFlatfile,
  useListener,
  Workbook,
} from "@flatfile/react";

import { useSnackbar } from "notistack";
import { ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { flatfilePublicKey } from "src/env";
import { FlatfileSubmitArgs } from "src/types/flatfileTypes";
import { ValidatorExec } from "src/utils";
import { AssembleTypography } from "./AssembleTypography";
import { useAuth } from "./Auth/AuthContext";

type Props = {
  render: (launch: () => void) => ReactNode;
  onData: (args: FlatfileSubmitArgs) => Promise<void>;
  onCancel?: () => void;
  onRecordChange?: (record: FlatfileRecord) => void;
  settings: Flatfile.CreateWorkbookConfig;
  validators?: ValidatorExec[];
};
export function AssembleFlatfileButton({
  onRecordChange,
  validators,
  ...props
}: Props) {
  const { sheets, ...config } = props.settings;

  const { organization } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  if (sheets == null) {
    enqueueSnackbar("No sheets found", { variant: "error" });
    return null;
  }

  const Button = () =>
    props.render(() => {
      setOpen(true);
    });

  const FlatfileApp = () => {
    const { openPortal, closePortal } = useFlatfile({
      onClose: () => {
        setOpen(false);
        props.onCancel?.();
      },
    });

    useListener((listener) => {
      if (onRecordChange != null || (validators?.length ?? 0) > 0) {
        listener.use(
          recordHook(sheets[0].slug ?? "", (record) => {
            onRecordChange?.(record);
            validators?.forEach((validator) => {
              validator(record);
            });
            return record;
          })
        );
      }
    });

    // open portal on mount
    // (this component is only mounted when the button is clicked)
    useEffect(() => {
      openPortal();
      // DO NOT RUN THIS EFFECT ON CHANGES
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Space>
        <Workbook config={config}>
          <Sheet
            onSubmit={async (results) => {
              try {
                if (typeof props.onData === "function") {
                  await props.onData(results);
                }
                document.body.classList.remove("flatfile-active");
                setTimeout(() => {
                  closePortal();
                  enqueueSnackbar({
                    message: "Data uploaded successfully",
                    variant: "success",
                  });
                }, 1_000);
              } catch (error) {
                console.error(error);
                enqueueSnackbar({
                  message: "Data upload failed",
                  variant: "error",
                });
              }
            }}
            config={{ ...sheets[0], name: "Sheet 1" }}
          />
        </Workbook>
      </Space>
    );
  };

  // import file directly
  if (organization == null) return null;
  if (sheets == null || sheets.length === 0)
    return <AssembleTypography>Loading...</AssembleTypography>;

  return (
    <>
      <Button />

      {open &&
        createPortal(
          <FlatfileProvider publishableKey={flatfilePublicKey()}>
            <FlatfileApp />
          </FlatfileProvider>,
          document.getElementById("flatfile_portal_target")!
        )}
    </>
  );
}
