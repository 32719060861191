import { FetchResult, gql, useMutation } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { mapMaybe } from "@asmbl/shared/utils";
import accounting from "accounting";
import { useCallback } from "react";
import { toFFRecordLike } from "src/utils";
import {
  EquityGrantTypes,
  ImportEmployeeEquityGrants,
  ImportEmployeeEquityGrantsVariables,
  UpsertEquityGrantEvents,
  UpsertEquityGrantEventsVariables,
} from "../__generated__/graphql";
import { EmployeeEquityGrantRow } from "../components/Settings/Equity/EquityImportButton";
import { EquityGrantVestingEventRow } from "../components/Settings/Equity/EquityVestingImportButton";
import { matchEmployee } from "../models/csv/EmployeeCSV";

const IMPORT_EMPLOYEE_EQUITY_GRANTS = gql`
  mutation ImportEmployeeEquityGrants($data: [EquityGrantInput!]!) {
    importEmployeeEquityGrants(data: $data) {
      id
      employeeNumber
      email
      personalEmail
      equityHoldings {
        id
        grants {
          id
          name
          unitCount
        }
      }
    }
  }
`;

const UPSERT_EQUITY_GRANT_VESTING_EVENTS = gql`
  mutation UpsertEquityGrantEvents(
    $equityGrantVestEvents: [EquityGrantVestEventUpsertInput!]!
  ) {
    upsertEquityGrantVestEvents(equityGrantVestEvents: $equityGrantVestEvents) {
      errors
      result {
        id
      }
      organization {
        id
        hasAnyEquityVestEvents
        lastEquityVestingEventsUploadDate
      }
    }
  }
`;

export function useImportEmployeeEquityGrants<
  E extends {
    id: number;
    employeeNumber: string | null | undefined;
    email: string;
    personalEmail: string | null | undefined;
  },
>(): (
  data: EmployeeEquityGrantRow[],
  employees: E[]
) => Promise<FetchResult<ImportEmployeeEquityGrants>> {
  const [importEmployeeEquityGrants] = useMutation<
    ImportEmployeeEquityGrants,
    ImportEmployeeEquityGrantsVariables
  >(IMPORT_EMPLOYEE_EQUITY_GRANTS);

  return useCallback(
    (pendingGrants, employees) => {
      const mappedData = mapMaybe(pendingGrants, (grantRow) => {
        const record = toFFRecordLike(grantRow);
        const employee = matchEmployee(record, employees);

        if (employee) {
          return {
            employeeId: employee.id,
            equityType: EquityGrantTypes.UNKNOWN,
            ...parseEquityGrantRowData(grantRow),
          };
        }
      });

      return importEmployeeEquityGrants({
        variables: {
          data: mappedData,
        },
      });
    },
    [importEmployeeEquityGrants]
  );
}

export function useUpsertEquityGrantVestingEvents<
  E extends {
    id: number;
    employeeNumber: string | null | undefined;
    email: string;
    personalEmail: string | null | undefined;
  },
>(): (
  equityGrantVestEvents: EquityGrantVestingEventRow[],
  employees: E[]
) => Promise<FetchResult<UpsertEquityGrantEvents>> {
  const [upsertEquityGrantEvents] = useMutation<
    UpsertEquityGrantEvents,
    UpsertEquityGrantEventsVariables
  >(UPSERT_EQUITY_GRANT_VESTING_EVENTS);
  return useCallback(
    async (equityGrantVestEvents, employees) => {
      const mappedData = mapMaybe(equityGrantVestEvents, (grantRow) => {
        const record = toFFRecordLike(grantRow);
        const employee = matchEmployee(record, employees);

        if (employee) {
          return {
            employeeId: employee.id,
            ...parseEquityGrantVestEventRowData(grantRow),
          };
        }
      });

      return upsertEquityGrantEvents({
        variables: { equityGrantVestEvents: mappedData },
      });
    },
    [upsertEquityGrantEvents]
  );
}

export function parseEquityGrantVestEventRowData(
  grantRow: EquityGrantVestingEventRow
) {
  return {
    grantName: grantRow.grantName,
    vestingDate: new Date(grantRow.vestingDate),
    unitCount: parseInt(grantRow.unitCount.replaceAll(",", ""), 10),
  };
}

export function parseEquityGrantRowData(grantRow: EmployeeEquityGrantRow) {
  return {
    name: grantRow.grantName,
    awardType: grantRow.awardType,
    vestingScheduleDescription: grantRow.vestingScheduleDescription,
    issueDate: new Date(grantRow.issueDate),
    vestingStartDate: new Date(grantRow.vestingStartDate),
    unitCount: parseInt(grantRow.unitCount.replaceAll(",", ""), 10),
    price: accounting.unformat(grantRow.price),
    priceCurrencyCode: grantRow.priceCurrencyCode as CurrencyCode,
  };
}
