import { Property } from "@flatfile/api/api";
import { FFValidator } from "src/utils";
import { INTEGER_VALIDATOR } from "./Validators";

export const EquityFields: Property[] = [
  {
    label: "New Equity Grant ($)",
    key: "equity",
    type: "number",
  },
  {
    label: "New Equity Grant (Units)",
    key: "equityUnits",
    type: "number",
  },
  {
    label: "Equity Grant Notes",
    key: "equityNotes",
    type: "string",
  },
];

export const getEquityValidators = () => [
  FFValidator.regexMatches(
    "equity",
    RegExp(INTEGER_VALIDATOR),
    "Must be a whole number"
  ),
  FFValidator.regexMatches(
    "equityUnits",
    RegExp(INTEGER_VALIDATOR),
    "Must be a whole number"
  ),
];
