import { EmploymentTypeEnum } from "@asmbl/shared/merge";
import { CreateWorkbookConfig } from "@flatfile/api/api";
import { EmploymentStatus } from "src/__generated__/graphql";
import { DATE_REGEX } from "src/constants";
import { FFValidator } from "src/utils";

export const HRIS_FLATFILE_SETTTINGS: CreateWorkbookConfig = {
  name: "Import your Employee data from a Payroll CSV.",
  sheets: [
    {
      name: "Import your Employee data from a Payroll CSV.",
      slug: "RipplingImportRow",
      fields: [
        {
          label: "Employee Number",
          key: "employeeNumber",
          description:
            "The employee's id needs to match to the id found in your HRIS",
          constraints: [{ type: "required" }],
          type: "string",
        },
        {
          label: "Work Email",
          key: "workEmail",
          constraints: [{ type: "required" }],
          type: "string",
        },
        {
          label: "Personal Email",
          key: "personalEmail",
          type: "string",
        },
        {
          label: "Full Name",
          key: "displayName",
          constraints: [{ type: "required" }],
          type: "string",
        },
        {
          label: "Job Status",
          key: "employmentStatus",
          type: "enum",
          config: {
            options: Object.values(EmploymentStatus).map((status) => ({
              label: status,
              value: status,
            })),
          },
          constraints: [{ type: "required" }],
        },
        {
          label: "Manager Work Email",
          key: "managerWorkEmail",
          type: "string",
        },
        {
          label: "Annual Compensation",
          key: "payRate",
          constraints: [{ type: "required" }],
          type: "number",
        },
        {
          label: "Currency",
          key: "payCurrency",
          constraints: [{ type: "required" }],
          type: "string",
        },
        {
          label: "Employment Type",
          key: "employmentType",
          type: "enum",
          config: {
            options: Object.values(EmploymentTypeEnum).map((type) => ({
              label: type,
              value: type,
            })),
          },
          constraints: [{ type: "required" }],
        },
        {
          label: "Job Title",
          key: "jobTitle",
          constraints: [{ type: "required" }],
          type: "string",
        },
        {
          label: "Pay Period Start",
          key: "effectiveDate",
          constraints: [{ type: "required" }],
          type: "date",
        },
        {
          label: "City",
          key: "city",
          constraints: [{ type: "required" }],
          type: "string",
        },
        {
          label: "State",
          key: "state",
          type: "string",
        },
        {
          label: "Country",
          key: "country",
          constraints: [{ type: "required" }],
          type: "string",
        },
        {
          label: "First Name",
          key: "firstName",
          type: "string",
        },
        {
          label: "Last Name",
          key: "lastName",
          type: "string",
        },
        {
          label: "Gender",
          key: "gender",
          type: "string",
        },
        {
          label: "Ethnicity",
          key: "ethnicity",
          type: "string",
        },
        {
          label: "Start Date",
          key: "startDate",
          type: "date",
        },
        {
          label: "End Date",
          key: "terminationDate",
          type: "date",
        },
      ],
    },
  ],
};
export const getHrisFlatfileValidators = () => [
  FFValidator.regexMatches(
    "effectiveDate",
    RegExp(DATE_REGEX.source),
    "Must be in format YYYY-MM-DD or MM/DD/YYYY"
  ),
  FFValidator.regexMatches(
    "startDate",
    RegExp(DATE_REGEX.source),
    "Must be in format YYYY-MM-DD or MM/DD/YYYY"
  ),
  FFValidator.regexMatches(
    "terminationDate",
    RegExp(DATE_REGEX.source),
    "Must be in format YYYY-MM-DD or MM/DD/YYYY"
  ),
];
export const HRIS_HOURLY_FLATFILE_SETTINGS: CreateWorkbookConfig = {
  name: "Import your Employee data from a Payroll CSV.",
  sheets: [
    {
      name: "Import your Employee data from a Payroll CSV.",
      slug: "HourlyRipplingImportRow",
      fields: [
        {
          label: "Employee Number",
          key: "employeeNumber",
          description:
            "The employee's id needs to match to the id found in your HRIS",
          constraints: [{ type: "required" }],
          type: "string",
        },
        {
          label: "Work Email",
          key: "workEmail",
          constraints: [{ type: "required" }],
          type: "string",
        },
        {
          label: "Personal Email",
          key: "personalEmail",
          type: "string",
        },
        {
          label: "Full Name",
          key: "displayName",
          constraints: [{ type: "required" }],
          type: "string",
        },
        {
          label: "Job Status",
          key: "employmentStatus",
          type: "enum",
          config: {
            options: Object.values(EmploymentStatus).map((status) => ({
              label: status,
              value: status,
            })),
          },
          constraints: [{ type: "required" }],
        },
        {
          label: "Manager Work Email",
          key: "managerWorkEmail",
          type: "string",
        },
        {
          label: "Annual Compensation",
          key: "payRate",
          type: "number",
          description:
            "Annual salary for the employee. Required if no hourly compensation is provided.",
        },
        {
          label: "Hourly Compensation",
          key: "hourlyPayRate",
          type: "number",
          description:
            "Hourly pay rate for the employee. Required if no annual compensation is provided.",
        },
        {
          label: "Currency",
          key: "payCurrency",
          constraints: [{ type: "required" }],
          type: "string",
        },
        {
          label: "Employment Type",
          key: "employmentType",
          type: "enum",
          config: {
            options: Object.values(EmploymentTypeEnum).map((type) => ({
              label: type,
              value: type,
            })),
          },
          constraints: [{ type: "required" }],
        },
        {
          label: "Job Title",
          key: "jobTitle",
          constraints: [{ type: "required" }],
          type: "string",
        },
        {
          label: "Pay Period Start",
          key: "effectiveDate",
          constraints: [{ type: "required" }],
          type: "date",
        },
        {
          label: "City",
          key: "city",
          constraints: [{ type: "required" }],
          type: "string",
        },
        {
          label: "State",
          key: "state",
          type: "string",
        },
        {
          label: "Country",
          key: "country",
          constraints: [{ type: "required" }],
          type: "string",
        },
        {
          label: "First Name",
          key: "firstName",
          type: "string",
        },
        {
          label: "Last Name",
          key: "lastName",
          type: "string",
        },
        {
          label: "Gender",
          key: "gender",
          type: "string",
        },
        {
          label: "Ethnicity",
          key: "ethnicity",
          type: "string",
        },
        {
          label: "Start Date",
          key: "startDate",
          type: "date",
        },
        {
          label: "End Date",
          key: "terminationDate",
          type: "date",
        },
      ],
    },
  ],
};

export const getHrisHourlyFlatfileValidators = () => [
  FFValidator.requiredWithValues("payRate", { hourlyPayRate: null }),
  FFValidator.requiredWithValues("hourlyPayRate", { payRate: null }),
  FFValidator.regexMatches(
    "effectiveDate",
    RegExp(DATE_REGEX.source),
    "Must be in format YYYY-MM-DD or MM/DD/YYYY"
  ),
  FFValidator.regexMatches(
    "startDate",
    RegExp(DATE_REGEX.source),
    "Must be in format YYYY-MM-DD or MM/DD/YYYY"
  ),
  FFValidator.regexMatches(
    "terminationDate",
    RegExp(DATE_REGEX.source),
    "Must be in format YYYY-MM-DD or MM/DD/YYYY"
  ),
];
